.login-register-heading {
  font-size: 20px;
  margin-bottom: 0;
}
.bit-link-new-pin {
  text-align: center;
  height: calc(100vh - 50vh);
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.bit-pin-desc {
  padding-top: 20px;
  color: #bbb;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 4px;
}
.bit-new-pin {
  display: flex;
  align-items: center;
  height: 24px;
  justify-content: center;
  height: 24px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.bit-new-pin div {
  border: solid 1px var(--inactive);
  border-radius: 100%;
  box-shadow: none;
  background: transparent;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  height: 10px;
  width: 10px;
  font-size: 16px;
  line-height: 10px;
  font-weight: bold;
  text-align: center;
  color: transparent;
  caret-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bit-new-pin div.done {
  height: 12px;
  margin-left: 9px;
  margin-right: 9px;
  width: 12px;
  color: #000000;
  border-color: white;
  animation: ipt-anim-pulse 0.5s ease forwards;
  -webkit-animation: ipt-anim-pulse 0.5s ease forwards;
}
.bit-custom-keyboard-box {
  background: var(--surface);
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.bit-custom-keyboard {
  display: flex;
  justify-content: center;
  flex-basis: auto;
  flex-wrap: wrap;
  width: 100%;

  bottom: 0;
  padding-top: 20px;
}
.bit-custom-keyboard div {
  color: var(--text-primary);
  position: relative;
  overflow: hidden;
  font-size: 28px;
  text-align: center;
  padding: 12px 0;
  width: 33.33%;
}
.bit-custom-keyboard div:nth-child(1) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, var(--border) 0%, var(--surface) 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(2) {
  border: 0;
  border-bottom: 1px solid var(--border);
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: linear-gradient(
    to bottom,
    var(--surface) 0%,
    var(--border) 100%
  );
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(3) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(
    to right,
    var(--border) 0%,
    var(--surface) 100%
  );
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(4) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, var(--border) 0%, var(--surface) 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(5) {
  border: 0;
  border: 1px solid var(--border);
  border-top: 0;
}
.bit-custom-keyboard div:nth-child(6) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(
    to right,
    var(--border) 0%,
    var(--surface) 100%
  );
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(7) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, var(--border) 0%, var(--surface) 100%);
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(8) {
  border: 0;
  border: 1px solid var(--border);
  border-top: 0;
}
.bit-custom-keyboard div:nth-child(9) {
  border: 0;
  border-bottom: 1px solid;
  border-image: linear-gradient(
    to right,
    var(--border) 0%,
    var(--surface) 100%
  );
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(10) {
}
.bit-custom-keyboard div:nth-child(11) {
  border: 0;
  border-left: 1px solid;
  border-right: 1px solid;
  border-image: linear-gradient(
    to bottom,
    var(--border) 0%,
    var(--surface) 100%
  );
  border-image-slice: 1;
}
.bit-custom-keyboard div:nth-child(12) {
}
.bit-custom-keyboard div:nth-child(12) img {
  width: 22px;
}
.bit-link-new-pin .login-register-icon img {
  height: 60px;
  margin-top: 20px;
}
@keyframes ipt-anim {
  0% {
    background: #fff;
    border: solid 1px #fff;
    box-shadow: none;
    color: #666;
  }
  50% {
    background: #fff;
    border: solid 1px #fff;
    box-shadow: none;
    color: #666;
  }
  60% {
    background: #00ab6b;
    border: solid 1px #ededed;
    box-shadow: 0px 0px 0px 5px rgba(210, 243, 230, 1);
    color: #00ab6b;
  }
  100% {
    background: #00ab6b;
    border: solid 1px #00ab6b;
    box-shadow: 0px 0px 0px 5px rgba(210, 243, 230, 1);
    color: #00ab6b;
  }
}
@keyframes ipt-anim-pulse {
  0% {
    background: #00ab6b;
    border: solid 1px #00ab6b;
    color: transparent;
    box-shadow: 0px 0px 0px 0px #00ab6b1a;
  }
  50% {
    background: #00ab6b;
    border: solid 1px #00ab6b;
    color: transparent;
    box-shadow: 0px 0px 0px 5px #00ab6b33;
  }
  100% {
    background: #00ab6b;
    border: solid 1px #00ab6b;
    color: transparent;
    box-shadow: 0px 0px 0px 5px #00ab6b33;
  }
}

@media (min-height: 640px) {
  .bit-link-new-pin {
    padding-top: 0;
  }
  .bit-custom-keyboard {
    padding-top: 10px;
  }
  .bit-custom-keyboard div {
    padding: 14px 0;
  }
  .bit-new-pin {
    margin-top: 20px;
  }
  .bit-link-new-pin .login-register-icon img {
    margin-top: 17px;
  }
}

@media (min-height: 700px) {
  .bit-link-new-pin {
    padding-top: 30px;
    background-color: var(--surface);
  }
  .bit-custom-keyboard div {
    padding: 20px 0;
  }
  .bit-custom-keyboard {
    padding-top: 0;
  }
}
@media (min-height: 800px) {
  .bit-link-new-pin {
    padding-top: 0;
  }
  .bit-custom-keyboard {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1030px) {
  .bit-link-new-pin {
    margin-top: 0;
  }
  .bit-link-new-pin .login-register-icon img {
    height: 60px !important;
    margin-top: 20px;
  }
  .bit-custom-keyboard div {
    padding: 12px 0 !important;
  }

  .profile-user-detail .am-navbar,
  .profile-user-detail .content-box {
    background-color: var(--surface-gray) !important;
  }
}
@media (max-height: 630px) {
  .bit-custom-keyboard div {
    padding: 6px 0;
  }
}
.bit-forgot-pin {
  margin-top: 18px;
  color: #00ab6b;
  font-size: 14px;
  line-height: 135%;
  font-weight: bold;
}

.bit-forgot-pin:hover {
  cursor: pointer;
}
.errorspan:empty {
  display: none;
}
.bit-pin-exceed {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}
.profile-user-detail .am-navbar,
.profile-user-detail .content-box {
  background-color: var(--surface);
}
.profile-user-detail .am-input-control input:disabled {
  background-color: var(--surface);
}
.profile-user-detail .am-input-disabled input {
  color: var(--text-primary) !important;
}
.bit-pin-exceed > div {
  transform: translateY(-36px);
}
.login-register-icon img {
  margin-bottom: 20px;
}
.bit-pin-exceed .login-register-icon img {
  height: 60px;
  margin-top: 20px;
}
.bit-pin-exceed button {
  margin: 20px auto 0;
  min-width: 118px;
}
.bit-pin-exceed p {
  color: #00ab6b;
  font-size: 12px;
  margin-top: 18px;
}
.bit-exceed-text {
  color: #ee4a49;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}
.bit-exceed-text + div {
  font-size: 13px;
}
@media only screen and (min-width: 1030px) {
  .bit-custom-keyboard-box {
    position: relative;
    margin: 0 auto;
  }
  .bit-custom-keyboard {
    padding: 0 140px;
  }
  .bit-custom-keyboard div {
    cursor: pointer;
  }
  .bit-pin-exceed {
    height: calc(100vh - 80px);
  }
}

@media only screen and (max-width: 340px) {
  .bit-custom-keyboard {
    padding-top: 0px;
  }
}
