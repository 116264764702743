.bibit-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: var(--surface);
}
.bibit-loader img {
  height: 25.13px;
  width: auto;
}

@media only screen and (min-width: 1030px) {
  .bibit-loader {
    background: var(--surface);
  }
}
