@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loader-simmer-line {
  animation: placeHolderShimmer 1s linear 0s infinite normal forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, var(--modal-surface-gray) 8%, var(--modal-surface) 18%, var(--modal-surface-gray) 33%);
  background-size: 800px 104px;
  border-radius: 20px;
  position: relative;
}
