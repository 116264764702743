.bit-datepicker-container {
  margin-top: 0px;
}
.bit-datepicker-container button {
  align-items: center;
  background: #00ab6b;
  border: 0;
  border-radius: 4px;
  color: #fff;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 100%;
}
.bit-datepicker-year {
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 4px;
  display: flex;
  height: 38px;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 5px 10px;
}
.bit-datepicker-year > div {
  align-items: center;
  display: flex;
}
.bit-datepicker-year img,
.bit-datepicker-year svg {
  color: #b5b5b5;
  width: 18px;
}
.bit-green-arrow img,
.bit-green-arrow svg {
  color: #00ab6b;
}
.bit-datepicker-month {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bit-datepicker-month > div {
  border: 1px solid #ededed;
  border-radius: 2px;
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 5px 0;
  width: calc((100% / 4) - 10px);
}
.bit-datepicker-month > div.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.bit-datepicker-month > div.active {
  background: #e6f7f0;
  border: 1px solid #00ab6b;
}
