.bibit-product-info-container {
  border-radius: 4px;
  background-color: var(--modal-green-low);
  padding: 12px;
  margin-bottom: 20px;
}

.bibit-product-info-icon {
  flex-grow: 0;
}

.bibit-product-info-text {
  font-size: 11px;
  line-height: 15px;
  color: var(--text-primary);
  margin-left: 12px;
}
