.bit-topup-gopay-success-modal {
  padding: 10px 20px 20px;
}
.bit-topup-gopay-success-modal .bit-modal-header {
  border-bottom: 1px solid rgba(0, 170, 19, 0.2);
  padding: 6px 0px;
}
.bit-topup-gopay-success-modal .bit-text-center {
  margin-top: 30px;
}
.bit-topup-gopay-success-modal .bit-text-center h3 {
  font-size: 20px;
  margin-bottom: 0;
}
.bit-topup-gopay-success-modal .bit-text-center p {
  margin-bottom: 16px;
  margin-top: 10px;
}
.bit-topup-gopay-success-modal .bit-text-center p img {
  margin-top: 5px;
  width: 62px !important;
}

.bit-gopay-success-pic {
  height: 92px !important;
  width: 92px !important;
}
.gopay-payment-title {
  margin: 24px 0px 8px;
}
.bit-success-gopay-logo {
  height: 20px !important;
  margin: 20px 0 -6px;
  width: auto !important;
}
.bit-success-gopay-logo + h3 {
  font-size: 20px !important;
}

.bit-success-logo-orderdetail {
  height: 16px;
  margin: 0 5px;
}

.bit-success-gopay-description-orderdetail picture {
  height: auto;
  width: 100%;
  margin: 0 3px;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 0;
}

.bit-success-gopay-logo-orderdetail img {
  width: auto;
  height: 16px;
}

.bit-success-shopeepay-logo-orderdetail {
  height: 18px;
  width: auto;
  margin: 0 3px !important;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 0;
}

.bit-success-jago-logo-orderdetail {
  width: auto;
  height: 22px;
}

.bit-success-gopay-small-logo {
  height: auto;
  width: 54px;
}

.bit-success-gopay-description-orderdetail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
