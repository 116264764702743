.exercise-modal {
  text-align: center;
}

.exercise-modal > p {
  max-width: 335;
  margin: 12px auto 36px auto;
}

.exercise-modal img {
  margin: 0 0 10px;
}

.exercise-modal *[role='button'] {
  font-weight: 700;
  font-size: 14px;
}
