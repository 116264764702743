.profit-card {
  text-align: left;
  display: flex;
  align-items: center;
  flex: 1 1;
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: #ffffff;
}

.profit-card--dark .circle {
  background-color: rgba(255, 255, 255, 0.08);
}

.profit-card--dark.money-market .circle {
  background-color: var(--green-default);
}

.profit-card--dark.bonds .circle {
  background-color: #318bc9;
}

.profit-card--dark.stocks .circle {
  background-color: #9d57cb;
}

.profit-card--dark.mixed .circle {
  background-color: #36a8ba;
}

.profit-card--dark .circle > svg {
  stroke: var(--surface);
}

.value {
  margin-left: 2.5px;
  white-space: nowrap;
}

.value > div,
.name {
  white-space: nowrap;
}

.profit {
  display: flex;
  align-items: center;
  height: 24px;
  overflow: hidden;
}

.portfolio-price-change {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: -3px;
}

.portfolio-price-green > div {
  color: #00ab6b;
}

.portfolio-price-green > svg {
  transform: rotate(-45deg);
  stroke: #00ab6b;
}

.portfolio-price-red > div {
  color: var(--red-default);
}

.portfolio-price-red > svg {
  transform: rotate(45deg);
  stroke: var(--red-default);
}

.loader {
  height: 16px;
  margin: 4px 0;
  width: 80px;
  border-radius: 20px;
  overflow: hidden;
}
.loader.money-market{
  background: var(--green-high) !important;
}
.loader.obligasi{
  background: var(--cobalt-high) !important;
}
.loader.stock{
  background: var(--violet-high) !important;
}
.loader.mix{
  background: var(--blue-high) !important;
}
.loader.cash{
  background: var(--brown-high) !important;
}

.loader div,
.loader svg {
  display: none;
}

@media only screen and (max-width: 360px) {
  .profit-card:first-child {
    margin-bottom: 0px;
  }
}
