.bit-btn-pay {
  margin: 0 18px 16px;
}

@media only screen and (min-width: 1030px) {
  .bit-btn-pay {
    max-width: 768px;
  }
}

.link-aja-modal .bit-btn-pay {
  margin: 0;
}

.cust-wrap-modal-title {
  margin-bottom: 18px;
  padding: 0 20px;
}
.cust-wrap-modal-title .modal-title {
  margin-top: 15px;
  justify-content: center;
}

.bit-stay-header-modal {
  display: flex;
  height: 45px;
  justify-content: space-between;
  margin-bottom: 0;
}
.bit-stay-header-modal div:last-child {
  min-width: 18px;
}
.bit-stay-header-modal .modal-title {
  margin-top: 0;
}
.bit-stay-header-modal + div {
  max-height: calc(100vh - 45px) !important;
  overflow: auto;
  padding-top: 20px;
}
.bit-stay-header-modal .modal-title {
  margin-top: 0;
}

.bit-payment-logo {
  margin-right: 6px;
  width: 20px;
}

.bit-voucher-selection-item {
  margin: 0 auto;
  padding: 10px 20px;
  position: relative;
}
