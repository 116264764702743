.banner-container {
  padding: 0px 20px;
}
.banner-wrapper-loading {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--green-low);
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--green-high);
}
.banner-wrapper {
  padding: 0px 20px;
  position: relative;
}
.banner-wrapper:hover {
  cursor: pointer;
}
.banner-top-left-loading {
  position: absolute;
  top: 0;
  left: 0;
}

.banner-bottom-right-loading {
  position: absolute;
  bottom: -4px;
  right: 0;
}
.banner-top-left {
  position: absolute;
  top: 0;
  left: 20px;
}

.banner-bottom-right {
  position: absolute;
  bottom: -4px;
  right: 20px;
}

.banner-text {
  flex: 1;
}

.banner-left {
  display: flex;
  align-items: center;
}

.banner-icon {
  box-sizing: border-box;
  width: 42px;
  height: 40px;
  margin-right: 20px;
}

.banner-icon > svg {
  width: 100%;
}

.banner-animation {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  border-radius: 20px;
  height: 96px;
  position: relative;
}

.banner-icon .banner-animation {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.banner-right-loader {
  width: 100%;
}
.banner-right-loader .banner-animation:first-child {
  height: 12px;
  width: 60%;
  border-radius: 10px;
  margin: 8px 0;
  max-width: 200px;
}

.banner-right-loader .banner-animation:nth-child(2) {
  height: 10px;
  width: 80%;
  margin: 6px 0;
  border-radius: 5px;
  max-width: 400px;
}
.banner-right-loader .banner-animation:nth-child(3) {
  height: 10px;
  margin: 6px 0;
  width: 40%;
  border-radius: 5px;
}

@media (min-width: 520px) {
  .banner-right-loader .banner-animation:first-child {
    margin: 6px 0;
  }
  .banner-right-loader .banner-animation:nth-child(3) {
    display: none;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
