div {
  --amount-width: var(--amountWidth);
}
.wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 12px;
}
.container {
  height: 30px;
}
.container-blurred {
  width: 92px;
}
.container-unblurred {
  width: var(--amount-width);
}
.container-show {
  width: var(--amount-width);
  -webkit-animation: container-show 0.5s;
  animation: container-show 0.5s;
}
.container-hide {
  width: 92px;
  -webkit-animation: container-hide 0.5s;
  animation: container-hide 0.5s;
}

.amount-container {
  position: absolute;
}
.nominal-wrapper {
  display: flex;
  align-items: baseline;
}
.white {
  color: var(--text-white);
}
.amount-wrapper {
  overflow: hidden;
  margin-right: 4px;
}
.nominal {
  height: 32px;
  font-size: 24px !important;
  font-weight: bold !important;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}
.animation-hide {
  opacity: 0;
  -webkit-animation: porto-hide 0.5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 0.1s;
  animation: porto-hide 0.5s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
.animation-show {
  opacity: 1;
  -webkit-animation: porto-show 0.7s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 0.1s;
  animation: porto-show 0.7s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
.bouncy-left-to-right {
  width: var(--amount-width);
  -webkit-animation: bouncy-left-to-right 0.6s;
  animation: bouncy-left-to-right 0.6s;
}
.bouncy-right-to-left {
  width: 92px;
  -webkit-animation: bouncy-right-to-left 0.5s;
  animation: bouncy-right-to-left 0.5s;
}

@keyframes porto-show {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes porto-hide {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes container-show {
  0% {
    width: 92px;
  }
  30% {
    width: 82px;
  }
  50% {
    width: 82px;
  }
  100% {
    width: var(--amount-width);
  }
}

@keyframes container-hide {
  0% {
    width: var(--amount-width);
  }
  30% {
    width: calc(var(--amount-width) + 10px);
  }
  50% {
    width: calc(var(--amount-width) + 10px);
  }
  100% {
    width: 92px;
  }
}

@keyframes bouncy-left-to-right {
  0% {
    width: 92px;
  }
  30% {
    width: 102px;
  }
  50% {
    width: 102px;
  }
  100% {
    width: var(--amount-width);
  }
}

@keyframes bouncy-right-to-left {
  0% {
    width: var(--amount-width);
  }
  30% {
    width: calc(var(--amount-width) - 10px);
  }
  50% {
    width: calc(var(--amount-width) - 10px);
  }
  100% {
    width: 92px;
  }
}
