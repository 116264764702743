.stock-chart-period {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: calc(100% - 80px);
  margin-left: 40px;
}

.stock-chart-period button {
  background-color: unset;
  border: unset;
  color: #00ab6b;
  font-size: 13px;
  background: transparent;
  padding: initial;
}

.stock-chart-period button.active {
  color: var(--text-primary);
  font-weight: bold;
  position: relative;
}

.stock-chart-period button.active::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
  height: 5px;
  width: 120%;
  border-bottom: solid 2px #00ab6b;
}

.stock-tooltip {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
}

.stock-chart {
  position: relative;
  overflow: hidden;
}

.animation {
  opacity: 0;
}

.hide {
  opacity: 0;
}

.show {
  opacity: 1;
}

.animation-fade {
  opacity: 0;
  animation: fade 0.15s ease-in-out 0.15s forwards;
}

.bit-chart-tooltip {
  position: absolute;
  font-size: 10px;
  text-align: center;
}

.background-animation {
  background: var(--surface);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  animation: fade-right 0.15s ease-in-out forwards;
}

.stock-chart-ipo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.stock-chart-ipo > div {
  margin-top: 32px;
  color: #969696;
}

@media only screen and (min-width: 1030px) {
  :global(.explore-stock) .background-animation {
    background: var(--surface);
  }
}

@keyframes fade-right {
  to {
    left: 100%;
  }
}

@keyframes fade {
  to {
    opacity: 1;
  }
}
