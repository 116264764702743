.headline1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
}

.headline2 {
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0;
}

.title1 {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
}

.title2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
}

.subtitle1 {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.subtitle2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.subtitle3 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
}

.body1 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.body2 {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.button1 {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.button2 {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
}

.button3 {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
}

.caption1 {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

.caption2 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

.overline1 {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
}

.overline2 {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
}
