/* Copy of BankJagoPage.css due css conflict */
.bit-jago-coregist-topbar {
  background-color: var(--green-low);
}
.bit-jago-tnc-page .content-header .am-navbar-light {
  background-color: var(--jago-high) !important;
}
.bit-jago-tnc-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}
.bit-jago-tnc-wrap .bit-jago-resume-container {
  flex: 1 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  transform: translateY(-60px);
  text-align: center;
  padding-top: 35px;
  padding-bottom: 80px;
}
.bit-jago-tnc-wrap h3 {
  font-size: 14px;
}
.bit-jago-tnc-box {
  border: 1px solid var(--border);
  border-radius: 7px;
  padding: 20px;
  position: relative;
  z-index: 10;
  margin-bottom: 20px;
}

.bit-jago-tnc-box-grid {
  border: 0;
  padding: 24px 0 0 0;
}

.bit-jago-advantages-grid-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 15px;
}

.bit-container-jago-tnc-advantages {
  padding: 0;
  border: 0;
}
.bit-jago-tnc-box ul {
  padding-left: 0;
}
.bit-jago-tnc-box ul li {
  background: url('assets/images/bank-jago/jago-checkbox.svg') no-repeat;
  padding-left: 30px;
  list-style: none;
  line-height: 20px;
  margin-bottom: 8px;
}
.bit-jago-tnc-bottom {
  margin-bottom: 20px;
}

.bit-jago-tnc-page .bit-jago-tnc-bottom {
  text-align: center;
}

.bit-jago-tnc-page .bit-jago-tnc-bottom img {
  width: 60px;
  margin-top: 5px;
}

.bit-jago-tnc-bottom label a {
  color: var(--green-default);
  font-weight: bold;
  text-decoration: underline;
}
.bit-jago-tnc-bottom .confirmRadio p {
  margin-top: 2px;
}
.bit-jago-tnc-bottom-fixed {
  bottom: 0;
  left: 0;
  position: fixed;
  padding: 20px;
  width: 100%;
  background-color: var(--surface);
}
.bit-jago-tnc-bottom .am-button-primary.am-button,
.bit-jago-primary-button {
  background: #fdaf27 !important;
  color: Black !important;
}
.bit-jago-tnc-bottom .am-button-primary.am-button-disabled {
  background: #ededed !important;
  color: #b5b5b5 !important;
}
.bit-jago-tnc-btn {
  margin-top: 10px;
}

.bit-jago-tnc-page .tnc-button-wrapper {
  box-shadow: var(--border) 0px 1px 0px inset;
  padding: 10px 20px;
  background: var(--surface);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.bit-jago-tnc-page .tnc-button-wrapper .HubungkanButton {
  background: #fdaf27 !important;
  border-radius: 6px;
  color: #141414;
  font-weight: 700;
  font-size: 14px;
}

.bit-jago-title {
  align-items: center;
  display: flex;
}

.bit-jago-pocket-detail {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}
.bit-jago-pocket-detail img {
  width: 50px;
}
.bit-jago-pocket-detail b {
  font-size: 15px;
  margin: 5px 0 3px;
}
.bit-jago-pocket-detail span {
  color: #707070;
  font-size: 10px;
  margin-top: 12px;
}
.bit-jago-pocket-wrap {
  padding-left: 20px;
}
.bit-jago-pocket-wrap.full-width {
  margin-left: -20px;
  padding-left: 0;
  width: calc(100% + 40px);
}
.bit-jago-pocket-wrap.full-width .bit-jago-pocket-row > div:first-child {
  padding-left: 20px;
}
.bit-jago-pocket-row {
  align-items: center;
  display: flex;
  font-weight: bold;
}
.bit-jago-pocket-row > div,
.bit-jago-pocket-row > a {
  align-items: center;
  border-bottom: 1px solid #ededed;
  display: flex;
  height: 52px;
  flex: 1;
}
.bit-jago-pocket-row > div:last-child {
  margin-left: auto;
  max-width: 35px;
}
.bit-jago-pocket-row > div:last-child svg {
  color: #00ab6b;
  width: 20px;
}
.bit-jago-pocket-row p {
  font-weight: normal;
}
.bit-jago-account-info-box {
  border: 1px solid var(--border);
  border-radius: 8px;
  margin-top: 20px;
}
.bit-jago-account-info-box-top {
  align-items: center;
  border-bottom: 1px solid var(--border);
  display: flex;
  padding: 20px;
}
.bit-jago-account-info-box-top img {
  margin-right: 14px;
  width: 36px;
}
.bit-jago-account-info-box-bottom {
  padding: 20px;
}
.bit-jago-account-info-box-bottom > div:first-child {
  margin-bottom: 10px;
}
.bit-jago-account-info-box-bottom > div > div {
  line-height: 22px;
}
.bit-jago-download-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 20px;
  background: var(--jago-low);
  border: 1px solid var(--jago-high);
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px;
}
.bit-jago-download-card .bit-jago-download-desc {
  width: 100%;
  align-items: center;
  display: flex;
}
.bit-jago-download-desc > .bit-uikit-flex {
  width: 100%;
}
.bit-jago-download-card .bit-jago-download-explanation {
  margin-right: 5px;
}
.bit-jago-download-card .bit-jago-download-desc > img {
  margin-right: 15px;
}
.bit-jago-download-card .bit-jago-download-btn {
  width: 100%;
}
.bit-jago-download-btn {
  background: #fdaf27 !important;
  font-size: 13px;
}
.bit-prof-separator.full-width {
  margin-left: -20px;
  width: calc(100% + 40px);
}
.bit-jago-rounded-border {
  border: 1px solid var(--border);
  border-radius: 6px;
  height: 40px;
  padding: 0 0 0 20px;
}
.bit-jago-rounded-border .bit-jago-pocket-row > div {
  border-bottom: 0;
  height: 38px;
}
.bit-jago-tnc-banner {
  margin-bottom: 8px;
}
.bit-jago-tnc-banner-back {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 13px;
  position: relative;
  z-index: 10;
}
.bit-jago-tnc-banner > img {
  width: 100%;
}
.bit-jago-row-flex {
  align-items: center;
  display: flex;
  margin: 10px 0 15px;
}
.bit-jago-column-flex {
  align-items: center;

  display: flex;
  flex-direction: column;
  /* margin: 10px 0 15px; */
}

.bit-jago-column-flex.grid-item {
  text-align: center;
  border: 1px solid var(--border);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 10px;
}
.bit-jago-column-flex.grid-item .grid-text-title {
  font-size: 13px;
}
.bit-jago-column-flex.grid-item img {
  margin-bottom: 5px;
}
.grid-text-description {
  font-size: 11px;
}

.bit-jago-row-flex:last-child {
  margin-bottom: 0;
}
.bit-jago-row-flex img,
.bit-jago-row-flex svg {
  min-width: 33px;
  margin-right: 15px;
  width: 33px;
}

.bit-jago-row-flex > div {
  text-align: left;
}
.bit-jago-tnc-checkbox {
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 7px;
  margin-bottom: 20px;
  padding: 20px 0 5px;
}
.bit-jago-tnc-checkbox .confirmRadio {
  padding-right: 15px;
}
.bit-jago-tnc-checkbox .confirmRadio p {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}
.bit-jago-tnc-checkbox .confirmRadio p span {
  color: #00ab6b;
  font-weight: bold;
  text-decoration: underline;
}
.bit-jago-tnc-checkbox > div {
  margin: 0 20px 0;
}
.bit-tnc-modal > div {
  align-items: flex-start;
  display: flex;
}
.bit-tnc-modal > div img {
  margin-right: 8px;
}
.bit-jago-rejected-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bit-jago-rejected-wrap > div:nth-child(1) {
  flex: 1 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  transform: translateY(-60px);
}
.bit-jago-rejected-wrap .bit-jago-tnc-bottom-fixed .bit-jago-tnc-btn {
  font-size: 14px;
  font-weight: bold;
}
.bit-jago-rejected-wrap
  .bit-jago-tnc-bottom-fixed
  .bit-jago-tnc-btn:nth-child(2) {
  background: none !important;
  color: #00ab6b;
}
.bit-jago-rejected-wrap p {
  line-height: 20px;
}
.bit-jago-tnc-checkbox-list {
  display: flex;
  margin-bottom: 5px !important;
}
.bit-jago-tnc-checkbox-list span {
  background: #fdaf27;
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-right: 10px;
  margin-top: 4px;
  min-width: 5px;
  width: 5px;
}
.bit-jago-tnc-checkbox-list.hide-dot span {
  background: unset;
}
.bit-jago-tnc-checkbox-section {
  border-top: 1px solid #ededed;
  margin-top: 14px !important;
}
.bit-jago-tnc-checkbox-section .confirmRadio {
  padding-left: 0;
}
.bit-jago-tnc-checkbox-section .am-checkbox-checked .am-checkbox-inner {
  background: #fdaf27 !important;
  border-color: #fdaf27 !important;
}
.bit-jago-benefit-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bit-jago-benefit-wrapper .bit-jago-benefit-subtitle-wrapper > p {
  font-size: 14px;
}

.bit-jago-benefit-wrapper .bit-jago-benefit-subtitle-wrapper {
  display: flex;
  align-items: center;
}

.bit-jago-benefit-wrapper .bit-jago-benefit-subtitle-wrapper .app-logo {
  display: flex;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  margin-top: -3px;
}

.bit-jago-benefit-wrapper .bit-jago-benefit-subtitle-wrapper img {
  height: 100%;
}

.bit-jago-arrow-grey {
  transition: all 0.5s ease-in-out;
}

.bit-jago-arrow-grey.arrow-down {
  transform: rotate(180deg);
  height: 16px;
}

.bit-jago-advantages-accordion-body {
  transition: all 0.5s ease-in-out;
  max-height: 700px;
  overflow: hidden;
  padding: 0 8px;
}
.bit-jago-advantages-accordion-body.hide-dropdown-body {
  max-height: 0;
}

.bit-jago-default-toggle-box {
  border: 1px solid #ededed;
  border-radius: 6px;
  height: 40px;
  padding: 0 20px;
  display: flex;
}
.bit-jago-default-toggle-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: #fff;
  white-space: nowrap;
  width: 100%;
}
.bit-jago-default-toggle-row b {
  border: solid 1px #fdaf27;
  border-radius: 40px;
  display: inline-block;
  height: 14px;
  width: 14px;
  position: absolute;
  left: -1px;
  top: -1px;
  background: #fff;
}
.bit-jago-default-toggle-row span {
  border: solid 1px #fdaf27;
  border-radius: 40px;
  display: inline-block;
  height: 14px;
  width: 28px;
  position: relative;
  margin-left: 7px;
  background: #fff9ef;
}
.bit-jago-default-toggle-row .switchON b {
  border: solid 1px #fdaf27;
  background: #fff;
  left: auto;
  right: -1px;
  top: -1px;
}
.bit-jago-default-toggle-row span.switchON {
  border: solid 1px #fdaf27;
  background: #fdaf27;
}
.bit-jago-default-toggle-row.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}
.bit-jago-default-toggle-row.disabled .bit-jago-default-toggle-text > div {
  opacity: 0.6;
}
.bit-jago-defaultp-toggle-row + div {
  border-top: 1px solid #ededed;
  animation: show-down 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.bit-jago-default-toggle-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bit-jago-default-toggle-text > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.bit-jago-default-toggle-text > div > div:nth-child(2) {
  color: #b5b5b5;
  font-size: 13px;
}

.bit-jago-deactive-modal-list {
  display: flex;
  flex-direction: column;
}

.bit-jago-deactive-list-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.bit-jago-deactive-item-image {
  height: 24px;
  margin-right: 12px;
}

.bit-jago-deactive-success-modal {
  padding: 10px 20px 20px;
}

.bit-jago-deactive-success-modal .bit-text-center h3 {
  font-size: 16px;
  margin-bottom: 0;
  padding: 0 15px;
}

.bit-jago-deactive-success-modal .bit-text-center p {
  margin-bottom: 24px;
  margin-top: 16px;
  padding: 0 15px;
}

.bit-jago-deactive-success-modal .bit-modal-header {
  border-bottom: 1px solid rgba(0, 170, 19, 0.2);
  padding: 6px 0px;
}

.bg-transparent {
  background: transparent;
}

@media only screen and (min-width: 1030px) {
  .bit-jago-download-card {
    max-width: 768px;
  }
  .bit-jago-tnc-box {
    background: #fff;
  }
  .bit-jago-tnc-bottom-fixed {
    left: 50%;
    margin-left: -384px;
    width: 768px;
  }
  .bit-jago-tnc-bottom .am-button-primary.am-button,
  .bit-jago-tnc-bottom .am-button-primary.am-button-disabled {
    border: 0 !important;
  }
  .bit-jago-tnc-checkbox {
    cursor: pointer;
  }
}

.bit-jago-tnc-item span {
  display: inline-block;
}
