.bibit-tabbar-wrap {
  border-top: 1px #ededed solid;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.bibit-tabbar-content-wrap {
  position: relative;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  display: flex;
  transition-duration: 0.2s;
  z-index: 100;
  justify-content: space-around;
  align-items: center;
  background: white;
}
.tabbar-bibit > div:nth-child(1) {
  z-index: 999;
}

.bibit-tabbar-content-wrap.bibit-plus-tabbar-content-wrap {
  height: 56px;
}

.bibit-plus-tabbar-content-wrap svg {
  width: 20px;
  height: 20px;
}

.bibit-plus-tabbar-content-wrap div svg path {
  stroke-width: 1.5 !important;
}

@media only screen and (min-width: 1030px) {
  .tabbar-bibit > div {
    background-color: var(--divider-thick);
  }
  .bibit-tabbar-wrap {
    background-color: #f9f9f9;
    position: fixed;
    z-index: 998;
    width: 768px;
    margin: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .bibit-tabbar-content-wrap {
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #ededed;
    position: relative;
    height: 60px;
    box-sizing: border-box;
    transform: translateY(-20px);
  }
}

.tabbar-profile-switch-account-tooltip-wrapper {
  position: relative;

  /* Disable text selection and copying */
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabbar-profile-switch-account-tooltip {
  position: absolute;
  right: 6px;
  white-space: nowrap;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #00ab6b;
  color: #fff;
  display: flex;
  align-items: center;
  overflow: visible !important;
  bottom: 42px;
  max-width: 300px;
  font-size: 12px;
  line-height: 16px;
}

.tabbar-profile-switch-account-tooltip::before {
  content: ' ';
  position: absolute;
  top: 100%;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #00ab6b;
}

.tabbar-profile-switch-account-tooltip-svg {
  margin-left: 8px;
  cursor: pointer;
}
