.info-modal :global .bit-modal-body {
  height: auto;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.info-modal :global .bit-modal-header {
  margin-bottom: 16px;
}

.info-modal :global .bit-title-text > h3 {
  font-size: 18px;
}

.info-modal-content {
  padding-bottom: 8px;
}

.info-modal h2 {
  margin: 0px;
  margin-bottom: 8px;
}

@media screen and (min-width: 1030px) {
  .info-modal :global .bit-modal-body {
    border-radius: 16px;
  }
}
