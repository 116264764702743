.bit-section-title {
  align-items: center;
  display: flex;
  max-height: 100px;
  padding: 20px 20px;
  justify-content: space-between;
}

.bit-section-title .bit-title {
  align-items: center;
  display: flex;
}

.bit-section-title .bit-title svg,
.bit-section-title .bit-title picture {
  margin-right: 6px;
}

.bit-section-title .bit-title p {
  font-weight: bold;
}