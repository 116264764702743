.dot-componnent {
  display: inline-block;
}

.dot-componnent span {
  display: inline-block !important;
  background: #00ab6b;
  border-radius: 50%;
  height: 6px;
  margin: 0 5px;
  width: 6px;
}
