.bit-product-list-modal {
  padding-bottom: 0px;
}

.bit-modal-select-wrapper {
  padding-top: 8px;
}

.bit-modal-item-container {
  display: flex;
  flex-direction: column;
}
.bit-modal-item-container :global .profiling-card-fragment >div:nth-child(1) >div:nth-child(2){
  border-top: 1px solid var(--modal-border) !important;
} 

.bit-discover-button {
  color: #1aa96e;
  border: 1px solid #1aa96e;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}

.bit-fixed-button-modal-wrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: var(--modal-surface);
  border-top: 1px solid var(--modal-border);
  opacity: 1;
  z-index: 10;
  left: 0px;
}
