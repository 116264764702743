@font-face {
  font-family: 'Proxima Nova Cn Rg';
  src: url('../node_modules/font-proxima-nova/fonts/ProximaNovaCond-Bold.eot');
  src: local('Proxima Nova Condensed Bold'), local('ProximaNovaCond-Bold'),
    url('../node_modules/font-proxima-nova/fonts/ProximaNovaCond-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../node_modules/font-proxima-nova/fonts/ProximaNovaCond-Bold.woff')
      format('woff'),
    url('../node_modules/font-proxima-nova/fonts/ProximaNovaCond-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  background-color: var(--surface-gray);
}

body {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

/* safari only */

::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

/* end of safari only */

input[type='color'],
input[type='datetime'],
input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px !important;
}

svg {
  user-select: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

.no-touch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  margin: 0;
  line-height: 1.24;
}

.backBigger {
  height: 45px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  left: -20px;
  background: transparent;
}

.homeTopRightIcons {
  display: flex;
  align-items: center;
}

.rightTopIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fullheight {
  height: 100%;
}

/* Width */
::-webkit-scrollbar {
  width: 0px !important;
  background-color: transparent !important;
  overflow: hidden !important;
  display: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  overflow-y: hidden !important;
  display: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  overflow-y: hidden !important;
  display: none !important;
}

/* Handle on .hov */
::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active,
::-webkit-scrollbar-thumb:focus {
  background-color: transparent !important;
  overflow-y: hidden !important;
  display: none !important;
}

:hover,
:active,
:focus,
:visited {
  outline: none;
  text-decoration: none;
}

.order-confirm .am-card {
  overflow: hidden;
}

.am-card.profiling-card:has(+ .instant-redemption-footer-card) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.modal-switch-done > div:nth-child(1) > div:nth-child(2) {
  display: none;
}

.reksadana .am-navbar-title > div {
  background: #fff;
  position: relative;
}

.bit-container-padding-horizontal {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;
  /* background-color: var(--surface); */
}

[data-name='modal-body'] .bit-container-padding-horizontal {
  background-color: var(--modal-surface);
}

.custom-modal-padding {
  margin-top: 0px !important;
  padding: 0px 20px 0px 20px !important;
}

.reksadana .am-navbar-title > div:after {
  background: #fff;
  content: '';
  position: absolute;
  bottom: -5px;
  display: block;
  left: -45px;
  right: -45px;
  height: 20px;
  z-index: -1;
}

.hide {
  display: none !important;
}
a {
  color: initial;
}
* {
  box-sizing: border-box;
}

body,
html {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Proxima Nova Rg', sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 1.24;
}

input,
select,
button {
  font-family: 'Proxima Nova Rg';
}

#root,
.App,
.router {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.no-select {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  -webkit-touch-callout: none;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
}
.router {
  background: var(--surface);
  position: relative;
}
.outerwrap {
  height: 100%;
}
.formobile {
  display: block;
}
.forweb {
  display: none;
}
.puller-box {
  width: 100%;
  position: relative;
  z-index: 1;
}
.portfolio .puller-box {
  height: 100% !important;
  z-index: unset;
}
.bit-modal-body > div:nth-child(3) .get-started-page {
  background-color: var(--modal-surface);
}
.bit-modal-body > div:nth-child(3) .get-started-page .content-box {
  background-color: var(--modal-surface);
}
.bit-modal-body
  > div:nth-child(3)
  .get-started-page
  .content-box
  > div:nth-child(1) {
  background-color: var(--modal-surface);
}
.bit-modal-body
  > div:nth-child(3)
  .get-started-page
  .content-box
  > div:nth-child(1)
  .bit-reksa-cards-wrap {
  background-color: var(--modal-surface);
}
.bit-modal-body
  > div:nth-child(3)
  .get-started-page
  .content-box
  > div:nth-child(1)
  .bit-reksa-cards-wrap
  .bit-container-padding-horizontal {
  background-color: var(--modal-surface);
}

.choose-product-modal-body .bit-container-padding-horizontal {
  background-color: var(--modal-surface);
}

.best-product-list-modal .bit-modal-body {
  max-height: calc(100vh - 55px);
  background-color: var(--modal-surface);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.profiling-card-fragment.modal > div:nth-child(1) {
  background-color: var(--modal-surface);
}

.invest-type-modal .bit-modal-body {
  background-color: var(--modal-surface);
}
.onboarding-icon-modal .bit-modal-body {
  background-color: var(--modal-surface);
}

@media only screen and (min-width: 1030px) {
  .router {
    max-height: 640px;
    width: 360px;
  }
  body {
    max-height: 640px;
    width: 360px;
    margin: auto;
  }
  .formobile {
    display: none;
  }
  .forweb {
    display: block;
  }
  .categories-im .content-box {
    height: auto !important;
  }
  .categories-im .reksadana-category-padding {
    padding-bottom: 0px !important;
  }

  .icon-support-wrapper {
    margin-right: 40px;
  }
  .loader-shimmer-chart {
    background-position-x: center;
    background-size: 50%;
    margin-left: unset !important;
  }
  .content-wrapper.newPin .am-navbar {
    background-color: transparent !important;
  }
  .institution-registration .content-box {
    background-color: var(--surface);
    height: calc(100vh - 160px) !important;
  }
  .institution-registration .content-header .am-navbar-light {
    background-color: var(--surface) !important;
    padding: 20px !important;
  }
  .institution-registration .rejected-ksei-footer {
    background-color: var(--surface-gray);
  }

  .best-of.discoverMoreBtn.bit-custom-discover-btn {
    margin: 20px;
  }
}

.content-wrapper {
  height: 100%;
  -js-display: flex;
  display: flex;
  background: var(--surface);
  background-repeat: no-repeat;
  flex-direction: column;
}

.wrapper-quickaccess-portfolio {
  position: relative;
  padding: 20px 20px 2px 20px;
  z-index: 99;
  background: var(--surface);
  height: auto;
  margin-top: 5px;
  transform: translateY(2px);
}

.content-wrapper .content-switch-product {
  width: 100%;
  -ms-touch-action: none;
  touch-action: pan-y;
  overflow-x: hidden;
  overflow-y: auto;
}

.instant-redemption-top-mutual-fund {
  flex: 0 !important;
  margin-left: -5px;
  margin-right: 12px;
}

.instant-redemption-rdcard {
  flex: 0 !important;
  margin-left: 6px;
  margin-right: 0px;
}

.logged-in .content-wrapper {
  background: var(--surface-gray);
}

.logged-in .content-wrapper.newPin {
  background: var(--surface);
}

.content-wrapper.newPin .am-navbar {
  background-color: var(--surface);
  color: var(--text-primary);
}

.logged-in.content-wrapper.reksadana,
.content-wrapper.reksadana {
  background-image: linear-gradient(var(--surface), var(--surface-gray));
}

.wrapper-standalone .content-wrapper {
  height: 100vh;
}
.content-wrapper.recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}

.categories-im .reksadana-category-padding {
  padding-bottom: 20px;
}

.content-wrapper.recaptcha.bg-active {
  background-image: url(./assets/images/portofolio-asset/curveBack.svg);
  background-size: cover;
}

.recaptcha > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.wrapper-standalone.nexto + div {
  display: none;
}
.index-recurring
  .content-box
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(1)
  > div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.product-list-category-modal .content-wrapper {
  overflow: unset !important;
  background-color: var(--modal-surface);
}
.product-list-category-modal .content-box {
  background-color: var(--modal-surface);
  overflow: unset;
}
.product-list-category-modal .puller-box {
  background: var(--modal-surface) !important;
}
.product-list-category-modal .bit-reksa-generic-card .bit-porto-card-top {
  border-bottom: none;
}
.product-list-category-modal .bit-reksa-generic-card .porto-card-btm {
  border-top: 1px solid var(--modal-border);
}
.product-list-category-modal .get-started-page .bit-reksa-cards-wrap {
  padding-bottom: 0px;
  background: var(--modal-surface) !important;
}

.product-list-category-modal .ptr--ptr {
  background-color: #fff;
  box-shadow: inset 0 -3px 5px rgba(52, 52, 52, 0.12);
}
.product-list-category-modal .get-started-page .bit-reksa-cards-wrap .am-card {
  border: 1px solid var(--modal-border) !important;
  background-color: var(--modal-surface);
}
.content-wrapper.login-register {
  background: var(--surface);
}

.content-wrapper.login-register .am-navbar {
  background: var(--surface);
}

.reksaListPageWrap {
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* To solve android 6 */
  padding: 15px 20px 0px 15px;
  margin-bottom: 20px;
}

.get-started-page .content-box {
  height: auto !important;
}

.am-steps-small .am-steps-item-icon > .am-steps-icon {
  top: 0;
}

.content-wrapper.login-register .g-primary-btn.fixed:after {
  border-top: 0;
}

.portfolio .content-wrapper,
.institution-registration.content-wrapper {
  background: var(--surface);
}

.institution-registration-draft .am-navbar {
  background: var(--surface);
}

.tabbar-bibit #bit-tabbar {
  z-index: 999 !important;
}

.notifications .am-navbar {
  background-color: var(--surface) !important;
}

.content-wrapper.portfolio {
  background: url(./assets/images/portfolio/bg-porto-green.png) no-repeat scroll
    center top var(--surface);
  background-size: 140% 230px;
}
.content-wrapper.portfolio.stable-earn {
  background-size: 140% 200px;
}
.content-wrapper.portfolio-empty {
  background: url(./assets/images/portfolio/bg-porto-green.png) no-repeat scroll
    center top;
  background-size: 140% 230px;
}
.content-wrapper.portfolio--dark,
.content-wrapper.portfolio--dark.portfolio-no-widget,
.content-wrapper.portfolio-empty.portfolio--dark {
  background: url(./assets/images/portfolio/bg-porto-green-dark.png) no-repeat
    scroll center top var(--surface);
  background-size: 140% 230px;
}

.content-wrapper.portfolio-no-widget {
  background: url(./assets/images/portfolio/bg-porto-green.png) no-repeat scroll
    center top;
  background-size: 140% 180px;
}

.content-wrapper.portfolio.shared-portfolio {
  background: url(./assets/images/shared-portfolio/bg-dot-shared-portfolio.svg),
    url(./assets/images/shared-portfolio/bg-dot-shared-portfolio.svg),
    url(./assets/images/shared-portfolio/bg-shared-portfolio.svg),
    var(--green-medium);
  background-size: 60px, 60px, 100%;
  background-position: left 44px, right 44px, top;
  background-repeat: no-repeat;
}
.content-wrapper.portfolio.shared-portfolio.shared-portfolio-dark {
  background: url(./assets/images/shared-portfolio/bg-dot-shared-portfolio-dark.svg),
    url(./assets/images/shared-portfolio/bg-dot-shared-portfolio-dark.svg),
    url(./assets/images/shared-portfolio/bg-shared-portfolio-dark.svg),
    var(--green-medium);
  background-size: 60px, 60px, 100%;
  background-position: left 44px, right 44px, top;
  background-repeat: no-repeat;
}
.content-wrapper.portfolio .content-header {
  height: 56px;
}
.content-wrapper.portfolio .content-header .am-navbar {
  height: 100%;
}

.wrapper-button-single-modal-sell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (min-width: 280px) {
  .content-wrapper.portfolio.shared-portfolio {
    background-size: 60px, 60px, 150%;
    background-position: left 50px, right 50px, center top;
  }
}

@media only screen and (min-width: 375px) {
  .content-wrapper.portfolio.shared-portfolio {
    background-size: 60px, 60px, 128%;
    background-position: left 50px, right 50px, center top;
  }
}

@media only screen and (min-width: 425px) {
  .content-wrapper.portfolio.shared-portfolio {
    background-size: 60px, 60px, 112%;
    background-position: left 50px, right 50px, center top;
  }
}

@media only screen and (min-width: 600px) {
  .content-wrapper.portfolio.shared-portfolio {
    background-position: left 50px, right 50px, center -100px;
  }
  .loader-shimmer-chart {
    background-position-y: center;
    background-position-x: center;
    background-size: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .content-wrapper.portfolio.shared-portfolio {
    background-size: 80px, 80px, 100%;
    background-position: left 50px, right 50px, center -200px;
  }
}

[class*='porto-goal'] .content-wrapper.portfolio {
  background-image: linear-gradient(var(--surface), var(--surface));
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: scroll;
}

.empty-state-portfolio {
  background: transparent;
}

.portfolio .am-navbar-light,
.portfolio .am-navbar-dark {
  background-color: transparent;
  height: 100%;
}

.portfolio .xyz {
  background-image: linear-gradient(#fff, #f9f9f9, #fff);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: scroll;
  min-height: 100%;
}

.empty-state {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.empty-state-portfolio {
  display: flex;
  flex-direction: column;
  height: calc(100% - 135px);
}
.bit-empty-total-height-100 {
  height: 100%;
}

.content-box {
  background: var(--surface);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.content-header {
  height: 44px;
  position: relative;
  z-index: 900;
  transition: height 0.3s;
}
.content-header-dark {
  background-color: #0d3024;
}

.new-content-header {
  height: 56px;
}

.new-content-header .am-navbar {
  height: 100%;
}

.order-finish .content-header .am-navbar {
  background-color: var(--surface);
}

.detail-dividen .content-box > a > .order-col {
  border-bottom: 1px solid var(--border);
}

.inlink {
  color: var(--green-default);
}
.daftar-btn {
  margin: 20px 0 !important;
  width: 100% !important;
}
@media screen and (min-width: 1030px) {
  .verification-register .login-register .content-box {
    min-height: 0 !important;
  }
  .get-started-page .content-box .bit-container-padding-horizontal {
    padding: 0 20px;
  }
  .get-started-page .content-box .get-started-banner-wrapper {
    padding: 20px;
  }
  .product-list-category-modal .bit-reksa-generic-card .porto-card-btm {
    border-top: none;
  }
}
.login-register > .content-box {
  display: block;
  background: var(--surface);
  min-height: calc(100vh - 80px) !important;
}

.login-register > .register-content-box {
  min-height: initial !important;
}

.primary-btn-fixed-wrap {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: var(--surface);
  border-top: 1px solid var(--border);
  opacity: 1;
  /* To overlay .infoIcon (z-index: 99) */
  z-index: 100;
}

.sell-order-detail-primary-btn-fixed-wrap {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: #fff;
  border-top: 1px solid #ededed;
  opacity: 1;
  /* To overlay .infoIcon (z-index: 99) */
  z-index: 100;
}

/* JSP edit Login/Register */

.jsp-middle-btn .primary-btn {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}

.login-register .am-button-primary.am-button-disabled {
  color: var(--surface);
  opacity: 1;
  background-color: var(--inactive-button) !important;
  border: none;
}

.login-register--dark .am-button-primary.am-button-disabled {
  background: var(--inactive-button) !important;
  color: var(--text-tertiary);
}

.am-steps-small .am-steps-item-title {
  font-size: 15px;
}

.general-title {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 12px;
}

.reksadana .general-title {
  font-size: 14px;
}

.discover-starting-page .general-title {
  margin-top: 12px;
}

/*Slick Slider*/

.slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 20px;
}
.slick-dots li button:before {
  display: none !important;
}
.slick-dots li {
  background: #eee;
  height: 6px;
  width: 6px;
  border-radius: 10px;
  vertical-align: middle;
}
.slick-dots li.slick-active {
  background: #00ab6b;
  height: 6px;
  width: 20px;
  border-radius: 15px;
}
.slick-arrow {
  display: none !important;
}

/*button*/

.am-button-warning {
  background-color: #ee4a49 !important;
}

.am-button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-color: #eee;
  background: transparent;
  overflow: visible;
}
.am-button-primary::before {
  border: solid 1px #ededed !important;
  display: none !important;
}
.am-button-primary {
  background-color: #00ab6b !important;
  border: 1px solid #00ab6b;
  box-shadow: none;
}
.am-button-primary.am-button-active {
  color: rgba(255, 255, 255, 0.3);
  background-color: #00ab6b;
}
.rounded-btn {
  width: fit-content;
  border-radius: 30px !important;
  margin: 0 auto;
  padding: 0 50px !important;
  text-align: center;
  font-size: 14px;
}
.bold {
  font-weight: 700;
}

.g-primary-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 15px;
  border: 0;
  background: #00ab6b;
  color: #fff;
  border-radius: 4px;
}
.primary-btn {
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: #00ab6b;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  width: 100%;
}

.primary-rounded-btn {
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: #00ab6b;
  color: #fff;
  border-radius: 40px;
  font-size: 15px;
  padding: 0 25px;
  margin: 0 auto;
}
.secondary-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  color: #00ab6b;
  border: solid 1px var(--border);
  border-radius: 4px;
  font-size: 15px;
  width: 100%;
}
.inline-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  color: #00ab6b;
  border-radius: 4px;
  font-size: 15px;
  width: 100%;
}
.dividen-modal-body > div:nth-child(1) {
  margin-bottom: 130px;
}
.primary-rounded-btn + .inline-btn {
  margin-top: 10px;
}

.saham-porto-skeleton {
  background: var(--green-high) !important;
  opacity: 1 !important;
}

.am-list-item .am-list-line .am-list-arrow {
  width: 12px;
  height: 12px;
}

.complete-your-profile .modal-content > div:nth-child(2) .bit-text-center > h3 {
  margin: 0px;
  padding: 24px 0px 8px;
}

.order-detail .primary-btn-fixed-wrap,
.reksadana .primary-btn-fixed-wrap {
  bottom: 0;
}
.register-account .primary-btn-fixed-wrap {
  bottom: 0;
}

.g-primary-btn.fixed:after {
  content: '';
  display: block;
  background: #fff;
  bottom: 40px;
  height: 10px;
  position: absolute;
  left: -15px;
  right: -15px;
  z-index: -1;
}

/*card*/

.recurring-choose-product
  .get-started-page-reksadana
  .am-card:not(.am-card-full) {
  border-color: var(--modal-border) !important;
}

.get-started-page-reksadana .am-card:not(.am-card-full) {
  border: solid 1px var(--border) !important;
}

.get-started-page-reksadana .am-card.card-selected:not(.am-card-full) {
  border: solid 1px var(--green-default) !important;
}

.get-started-page-reksadana .reksadana-page-contentbox {
  overscroll-behavior: none;
}

.choose-product-modal-body .am-card:not(.am-card-full) {
  border: solid 1px var(--modal-border) !important;
}

.modal-content .am-card:not(.am-card-full) {
  border: solid 1px var(--modal-border) !important;
}

.modal-content .am-card.card-selected {
  border: solid 1px var(--green-default) !important;
}

.am-card:not(.am-card-full) {
  border: solid 1px var(--border) !important;
  border-radius: 4px;
}

.am-card.profiling-card.card-selected:not(.am-card-full) {
  border: solid 1px var(--green-default) !important;
}

.bit-modal .am-card:not(.am-card-full),
.modal-choose-reksadana .am-card:not(.am-card-full) {
  border: solid 1px var(--modal-border) !important;
  background-color: var(--modal-surface);
}
.bit-modal .am-card.card-selected:not(.am-card-full),
.modal-choose-reksadana .am-card.card-selected:not(.am-card-full) {
  border: solid 1px var(--green-default) !important;
}

.am-card:not(.am-card-full)::before {
  border: solid 1px var(--border) !important;
  display: none !important;
}
.am-card {
  padding-bottom: 0;
  background: var(--surface);
}

/* Standart title size (16px)*/

.am-navbar-title,
.am-navbar-title span,
.am-navbar-light .am-navbar-title {
  font-size: 16px !important;
  white-space: nowrap;
  line-height: 1.3;
}

/* tabbar */
.am-tab-bar-bar {
  border-top: solid 1px #ededed !important;
}
.am-tab-bar-bar::before {
  display: none !important;
}
@media only screen and (max-width: 1030px) {
  .bibit-plus .tabbar-bibit #bit-tabbar > div:first-child {
    position: relative;
    box-sizing: border-box;
    height: 56px;
    padding: 4px 6px;
    display: flex;
    transition-duration: 0s;
    z-index: 100;
    justify-content: space-around;
    align-items: center;
  }

  .tabbar-bibit #bit-tabbar > div:first-child {
    position: relative;
    box-sizing: border-box;
    height: 56px;
    padding: 0 6px;
    display: flex;
    transition-duration: 0.2s;
    z-index: 100;
    justify-content: space-around;
    align-items: center;
  }
}

/*navbar*/

.am-navbar-title {
  font-weight: bold;
  font-size: 16px;
  padding: 0 10px;
}
.navbar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.am-navbar-left,
.am-navbar-title,
.am-navbar-right {
  flex: initial;
}
.am-navbar-left {
  width: 45px;
}
.am-navbar-right {
  margin-right: 0;
  padding-right: 16px;
  width: 45px;
}
.am-navbar-left {
  padding-left: 16px;
}
.am-navbar-title {
  text-align: center;
  white-space: inherit;
  width: calc(100% - 90px);
}

.am-navbar-light .am-navbar-title {
  color: var(--text-primary);
  font-size: 18px;
}

/*tab */
.content-box .am-tabs-pane-wrap {
  overflow: auto !important;
  transform: none !important;
  position: relative;
}

.am-tabs-default-bar-tab-active {
  color: #00ab6b;
}
.am-tabs-default-bar-underline {
  border-top: 0;
  border-bottom: 2px solid #00ab6b;
  z-index: 100;
}
.underline {
  text-decoration: underline;
}

/*badge*/
.am-badge-text {
  background: #00ab6b;
}
.am-badge-dot {
  background: #00ab6b;
}
.badge-porto-pending .am-badge-text {
  background: transparent;
  color: var(--text-primary);
  border-radius: 2px;
  margin-bottom: 15px;
}
.porto-pending-mr {
  margin-bottom: 20px;
}
.bank-picker .am-list .am-list-body::before {
  display: none;
}
.bank-picker .am-list .am-list-body::after {
  display: none;
}

.chart-label-tooltip {
  background: white !important;
  border: solid 1px #ededed;
  padding: 0 5px;
}
.chart-label-tooltip {
  position: relative;
  border: 1px solid #000;
  border-left: 0;
  font-size: 11px;
}
.switching-list > div > span {
  margin-left: 6px !important;
}
.chart-label-tooltip:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  position: absolute;
  left: -6px;
  top: 1px;
  border: solid 1px #000;
  border-width: 0 0 1px 1px;
}

/*list */
.list-std {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0;
  margin: 5px 0;
  border: solid 1px var(--divider-thin);
  border-width: 1px 0;
}

.list-std + .list-std {
  border-top: 0;
  margin-top: -5px;
}

.list-sd {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0;
}

.margin-bottom-2 {
  margin-bottom: 2px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-12 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-right-4 {
  margin-right: 6px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-6 {
  margin-right: 6px;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-right-12 {
  margin-right: 12px;
}
.margin-right-15 {
  margin-right: 15px;
}

.margin-left-6 {
  margin-left: 6px;
}
.margin-horizontal-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-horizontal-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.padding-20 {
  padding: 20px;
}
.padding-15 {
  padding: 15px;
}
.padding-10 {
  padding: 0 10px;
}
.padding-left-0 {
  padding-left: 0;
}
.padding-12 {
  padding: 12px;
}
.padding-left-16 {
  padding-left: 16px;
}
.padding-left-8 {
  padding-left: 8px;
}
.padding-12-10 {
  padding: 12px 10px;
}
.padding-bottom-45 {
  padding-bottom: 45px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-20-left-right {
  padding: 0 20px;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-bottom-8 {
  padding-bottom: 8px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-horizontal-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.padding-horizontal-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.padding-horizontal-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.padding-vertical-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.padding-vertical-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-vertical-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-right-8 {
  padding-right: 8px;
}

.order-confirm .list-std {
  border-bottom: solid 1px var(--divider-thin) !important;
}

.list-std > div:nth-child(1) {
  text-align: left;
  color: var(--text-primary);
}

.list-std > div:nth-child(1) b {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  display: inline-block;
}

.list-std > div:nth-child(2) {
  width: 25%;
  text-align: right;
}
.list-std > div:nth-child(2) b {
  font-size: 15px;
  font-weight: bold;
}
.list-std > div {
  width: 100%;
}
.list-std-title {
  margin-bottom: 5px;
}
.list-std:last-child {
  border-bottom: 0;
}

.list-std.green-background {
  width: calc(100% + 40px);
  margin-left: -20px;
  padding: 10px 20px;
}
.list-std.green-background--dark {
  border-bottom: solid 1px var(--green-low);
}

/*color*/
.greenval {
  color: #00ab6b !important;
}
.redval {
  color: var(--red-default) !important;
}
.lightgrayval {
  color: #b5b5b5;
}
.zeroval {
  color: var(--text-primary);
}

/* sell */
.modal-pad {
  padding-top: 20px;
}
.portfolio-detail-modal {
  padding: 20px 0 0 !important;
  text-align: left;
  max-height: calc(100vh - 48px);
  overflow: auto;
  overscroll-behavior: none;
}
.modal-quick-sell .portfolio-detail-modal {
  padding: 0 20px 20px 20px;
}

.sell-modal-button-wrap {
  display: flex;
  justify-content: space-between;
}
.sell-modal-button-wrap a:nth-child(1) {
  background: #ee4a49 !important;
  color: #fff;
}
.sell-modal-button-wrap a {
  width: calc((100% - 20px) / 2);
}
.modal-sell-list {
  padding: 5px 0;
}
.smallgray {
  color: #b5b5b5;
  font-size: 13px;
}

.half-width {
  width: 50%;
  padding: 8px 0;
}
.slider-wrap {
  margin: 20px 4px;
}

.half-width + .half-width {
  border-left: solid 1px #ededed;
  padding-left: 20px;
}

.modal-sell-list .half-width {
  width: 50%;
}

.modal-sell-list-duo {
  display: flex;
  margin: 8px 0;
  padding: 0;
  border: solid #ededed;
  border-width: 1px 0;
}
.modal-sell-list .am-list-item.am-input-item {
  padding-left: 0;
  border-bottom: solid 1px #ededed;
}

.am-slider-handle {
  border-color: var(--green-default);
  background-color: var(--surface);
}
.am-slider-track {
  background: #00ab6b;
}
.modal-title {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  height: 50px;
  color: var(--text-primary);
}

/*modal*/
.am-modal-body {
  color: #333;
  line-height: 1.24;
}
.am-modal-mask {
  z-index: 1000;
}
.am-modal-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1000;
}
.z-index-1001 {
  z-index: 1001;
}

.am-modal-wrap .am-modal-popup-slide-up {
  overflow: auto;
}

/*keyboard*/
.simple-keyboard {
  position: absolute;
  bottom: -50px;
  z-index: 1000;
}
.simple-keyboard.hg-theme-default {
  background: #fff;
}
.simple-keyboard .hg-row {
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}
.simple-keyboard.hg-theme-default .hg-button {
  box-shadow: none;
  font-size: 28px;
  border: 0;
  color: #b6b6b6;
  height: 50px;
  width: 100px;
}
.hg-button-bksp {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNDY5LjMzMyw2NGgtMzIwYy0xNC43MiwwLTI2LjM0Nyw3LjQ2Ny0zNC4wMjcsMTguNzczTDAsMjU1Ljg5M2wxMTUuMzA3LDE3My4xMkMxMjIuOTg3LDQ0MC4zMiwxMzQuNjEzLDQ0OCwxNDkuMzMzLDQ0OCAgICBoMzIwQzQ5Mi45MDcsNDQ4LDUxMiw0MjguOTA3LDUxMiw0MDUuMzMzVjEwNi42NjdDNTEyLDgzLjA5Myw0OTIuOTA3LDY0LDQ2OS4zMzMsNjR6IE00MDUuMzMzLDMzMi40OGwtMzAuMTg3LDMwLjE4NyAgICBsLTc2LjQ4LTc2LjQ4bC03Ni40OCw3Ni40OEwxOTIsMzMyLjQ4TDI2OC40OCwyNTZMMTkyLDE3OS41MmwzMC4xODctMzAuMTg3bDc2LjQ4LDc2LjQ4bDc2LjQ4LTc2LjQ4bDMwLjE4NywzMC4xODdMMzI4Ljg1MywyNTYgICAgTDQwNS4zMzMsMzMyLjQ4eiIgZmlsbD0iI2I2YjZiNiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: auto 28px !important;
}

.hg-button-enter {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0OTIuMDA0IDQ5Mi4wMDQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5Mi4wMDQgNDkyLjAwNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTQ4NC4xNCwyMjYuODg2TDMwNi40Niw0OS4yMDJjLTUuMDcyLTUuMDcyLTExLjgzMi03Ljg1Ni0xOS4wNC03Ljg1NmMtNy4yMTYsMC0xMy45NzIsMi43ODgtMTkuMDQ0LDcuODU2bC0xNi4xMzIsMTYuMTM2ICAgIGMtNS4wNjgsNS4wNjQtNy44NiwxMS44MjgtNy44NiwxOS4wNGMwLDcuMjA4LDIuNzkyLDE0LjIsNy44NiwxOS4yNjRMMzU1LjksMjA3LjUyNkgyNi41OEMxMS43MzIsMjA3LjUyNiwwLDIxOS4xNSwwLDIzNC4wMDIgICAgdjIyLjgxMmMwLDE0Ljg1MiwxMS43MzIsMjcuNjQ4LDI2LjU4LDI3LjY0OGgzMzAuNDk2TDI1Mi4yNDgsMzg4LjkyNmMtNS4wNjgsNS4wNzItNy44NiwxMS42NTItNy44NiwxOC44NjQgICAgYzAsNy4yMDQsMi43OTIsMTMuODgsNy44NiwxOC45NDhsMTYuMTMyLDE2LjA4NGM1LjA3Miw1LjA3MiwxMS44MjgsNy44MzYsMTkuMDQ0LDcuODM2YzcuMjA4LDAsMTMuOTY4LTIuOCwxOS4wNC03Ljg3MiAgICBsMTc3LjY4LTE3Ny42OGM1LjA4NC01LjA4OCw3Ljg4LTExLjg4LDcuODYtMTkuMUM0OTIuMDIsMjM4Ljc2Miw0ODkuMjI4LDIzMS45NjYsNDg0LjE0LDIyNi44ODZ6IiBmaWxsPSIjYjZiNmI2Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==) !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: auto 16px !important;
}

.index-registration-step .am-steps-item {
  flex-direction: column;
  display: flex;
  justify-content: content;
  align-items: center;
  width: calc(100% / 4);
}

.index-registration-step .am-steps-label-vertical .am-steps-item-tail {
  margin-left: 50%;
}
.index-registration-step .am-steps-label-vertical .am-steps-item-icon {
  margin: 0;
}

.am-steps-item-finish .am-steps-item-tail:after {
  background-color: #00ab6b;
}
.am-steps-item-finish .am-steps-item-icon > .am-steps-icon {
  color: #00ab6b;
}
.am-steps-item-process .am-steps-item-icon {
  border-color: #00ab6b;
}
.am-steps-item-process .am-steps-item-icon {
  background-color: #00ab6b;
}

.am-image-picker-list .am-image-picker-upload-btn:before,
.am-image-picker-list .am-image-picker-upload-btn:after {
  background: none;
  height: auto;
  width: auto;
  display: inline-block;
}

.am-image-picker-list .am-image-picker-upload-btn:before {
  content: 'Ambil Gambar';
  width: 100px;
  height: 60px;
  background-position: center 12px;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDYxMiA2MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYxMiA2MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYmFja3VwIj4KCQk8cGF0aCBkPSJNNDk0LjcsMjU1QzQ3Ni44NSwxNjguMyw0MDAuMzUsMTAyLDMwNiwxMDJjLTczLjk1LDAtMTM3LjcsNDAuOC0xNjguMywxMDJDNTguNjUsMjE0LjIsMCwyNzcuOTUsMCwzNTcgICAgYzAsODQuMTUsNjguODUsMTUzLDE1MywxNTNoMzMxLjVjNzEuNCwwLDEyNy41LTU2LjEsMTI3LjUtMTI3LjVDNjEyLDMxNi4yLDU1OC40NSwyNjAuMSw0OTQuNywyNTV6IE0zNTcsMzMxLjV2MTAySDI1NXYtMTAyICAgIGgtNzYuNUwzMDYsMjA0bDEyNy41LDEyNy41SDM1N3oiIGZpbGw9IiMwMGFiNmIiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 12px;
  color: #b5b5b5;
  font-size: 15px;
}

.oldimage {
  padding: 10px 0;
  border-radius: 4px;
  border: solid 1px #ededed;
  margin-top: 10px;
}
.am-list-line::after,
.am-list-line::before {
  background: none !important;
}
.am-flexbox + .am-flexbox {
  margin-bottom: 0;
}
.replaceimage .am-flexbox + .am-flexbox .am-flexbox-item {
  height: 40px;
}
.login-register
  .replaceimage
  .am-image-picker-list
  .am-image-picker-upload-btn {
  height: 20px;
}
.replaceimage .am-image-picker-list .am-image-picker-upload-btn:before {
  content: 'Ubah';
}
.login-register
  .replaceimage
  .am-image-picker-list
  .am-image-picker-upload-btn {
  border: 0;
}

.am-image-picker-list .am-image-picker-upload-btn input {
  width: 100%;
}

.replaceimage .am-image-picker-list .am-image-picker-upload-btn:before {
  height: 20px;
  background: none;
  padding-bottom: 0;
  align-items: center;
}
.am-image-picker-list .am-image-picker-upload-btn:after {
  content: '';
}

.tanda-tangan:empty {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDg5My41MzUgODkzLjUzNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgODkzLjUzNSA4OTMuNTM1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyMy4zODQsMGwtNzQuNDYsNDQ2Ljc2OGMyMjMuMzg0LDE0OC45MjMsMjYwLjYxNCw0NDYuNzY3LDI2MC42MTQsNDQ2Ljc2N2gyMy4yNjhWNDI1LjgyNiAgIGMtMjMuNzA1LTYuNDcyLTQxLjg4My0yNy43NC00MS44ODMtNTMuNTE4YzAtMzAuODMzLDI1LjAxNC01NS44NDgsNTUuODQ2LTU1Ljg0OHM1NS44NDYsMjUuMDE1LDU1Ljg0Niw1NS44NDggICBjMCwyNS43NzctMTguMTgsNDcuMDQ2LTQxLjg4Myw1My41MTh2NDY3LjcwOWgyMy4yNjhjMCwwLDM3LjIzLTI5Ny44NDQsMjYwLjYxMy00NDYuNzY3TDY3MC4xNTIsMEgyMjMuMzg0eiIgZmlsbD0iIzAwYWI2YiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.index-registration-step {
  padding: 20px;
  padding-bottom: 0;
}
.am-picker-popup-item {
  color: var(--green-default);
}
.am-picker-popup-item-active {
  background-color: var(--modal-surface);
}
.am-tabs-pane-wrap {
  overflow: visible;
}

.errorspan {
  color: var(--red-default);
  padding-top: 5px;
}

@media only screen and (max-width: 345px) {
  .am-steps-small .am-steps-item-title {
    font-size: 12px;
  }
}

.color-green {
  color: #00ab6b;
}
.color-white {
  color: #fff;
}
.color-gray {
  color: #bbb;
}

.bg-white {
  background-color: #fff !important;
}

.reksadana-chart tspan {
  font-size: 16px !important;
}
.alokasispan {
  display: inline-block;
  width: 150px;
}
.value-stepper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
}

.value-stepper-btn {
  display: flex;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  border: solid 1.5px var(--cobalt-default);
  align-items: center;
  justify-content: center;
}

.value-stepper-val {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 45px;
  margin: 0 5px;
  font-size: 18px;
  color: var(--text-primary);
}

.value-stepper-val span {
  color: var(--text-tertiary);
  font-size: 13px;
  font-weight: normal;
}

.shimmerLineLoader {
  height: 10px;
  display: inline-block;
  width: 50px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    var(--modal-surface-gray) 8%,
    var(--modal-surface) 18%,
    var(--modal-surface-gray) 33%
  );
  background-size: 800px 104px;
  border-radius: 5px;
}

.shimmerCircleLoader {
  display: inline-block;
  width: 50px;
  height: 50px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    var(--modal-surface-gray) 8%,
    var(--modal-surface) 18%,
    var(--modal-surface-gray) 33%
  );
  background-size: 800px 104px;
  border-radius: 50px;
}

.loader-shimmer-chart {
  height: 300px;
  background-image: url(./assets/images/sbn/shimmer-loader-chart.svg);
  background-repeat: no-repeat;
  margin-left: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes waveKeyFrame {
  40% {
    background-position: left;
  }
  100% {
    background-position: left;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    var(--modal-surface-gray) 8%,
    var(--modal-surface) 18%,
    var(--modal-surface-gray) 33%
  );
  background-size: 800px 104px;
  border-radius: 20px;
  height: 96px;
  position: relative;
}

.loaderp1 {
  color: transparent;
  height: 9px;
  overflow: hidden;
  display: inline-block;
  margin: 7px 0 30px 0;
  width: 150px;
}
.loaderp2 {
  color: transparent !important;
  height: 9px;
  overflow: hidden;
  display: inline-block;
  margin: 4px 0 !important;
  width: 100px;
}
.loaderp2-short {
  color: transparent !important;
  height: 9px;
  overflow: hidden;
  display: block;
  margin: 4px 0 !important;
  width: 40px;
}
.loaderp2-medium {
  color: transparent !important;
  height: 9px;
  overflow: hidden;
  display: block;
  margin: 4px 0 !important;
  width: 80px;
}
.loaderp2-long {
  color: transparent !important;
  height: 9px;
  overflow: hidden;
  display: block;
  margin: 4px 0 !important;
  width: 180px;
}
.loaderp3 {
  color: transparent !important;
  height: 9px;
  overflow: hidden;
  display: inline-block;
  margin: 4px 0 !important;
  width: 80px;
}
.loaderp3-long {
  color: transparent !important;
  height: 12px;
  overflow: hidden;
  display: block;
  margin: 4px 0 !important;
  width: 250px;
}
.loaderp4 {
  color: transparent;
  height: 16px;
  overflow: hidden;
  display: inline-block;
  margin: 8px 0 8px 0;
  width: 150px;
}
.loaderp5 {
  color: transparent;
  height: 32px;
  overflow: hidden;
  display: inline-block;
  margin: 30px 0 8px 0;
  width: 100%;
}
.loaderp6 {
  color: transparent !important;
  height: 9px;
  overflow: hidden;
  display: block;
  margin: 4px 0 !important;
  width: 60px;
}
.loaderp6-long {
  color: transparent !important;
  height: 12px;
  overflow: hidden;
  display: block;
  margin: 4px 0 !important;
  width: 110px;
}
.loaderp6-large {
  color: transparent !important;
  height: 12px;
  overflow: hidden;
  display: block;
  margin: 6px 0 !important;
  width: 60px;
}
.loaderp3box {
  display: flex;
  align-items: flex-end;
}

.loader-shimmer-button {
  border-radius: 4px;
  margin-left: 3px;
  height: 40px;
  margin-top: 10px;
  width: 100%;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    var(--modal-surface-gray) 8%,
    var(--modal-surface) 18%,
    var(--modal-surface-gray) 33%
  );
}

.loader-tabs-shimmer {
  border-radius: 20px;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 5px;
  width: 30%;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
}

.loader-box-shimmer {
  border-radius: 20px;
  margin-left: 3px;
  height: 20px;
  margin-top: 10px;
  width: 100px;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
}

.home-porto-slider .loaderp2 {
  width: 80px;
}
.earnings {
  text-align: right;
}

.text-small {
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-bold {
  font-weight: 700;
}
.text-normal {
  font-weight: 400;
}

.border-none {
  border: none;
}

.am-list-extra {
  display: none;
}

.recomend-loader {
  height: 10px;
}

.bit-identity-image-loading {
  width: 100%;
  height: 120px;
}

.am-picker .am-picker-col:nth-child(1) {
  order: 2;
}
.am-picker .am-picker-col:nth-child(2) {
  order: 1;
}
.am-picker .am-picker-col:nth-child(3) {
  order: 0;
}

body {
  flex-direction: column;
}

.am-search-input .am-search-synthetic-ph-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  text-align: left;
}

.am-search-input .am-search-clear {
  top: 4px;
}

.am-badge-text {
  font-family: 'Proxima Nova Rg';
  font-size: 13px !important;
}

.boxloader {
  background: var(--surface);
  width: 100%;
  height: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bit-modal .boxloader,
[data-name='modal-body'] .boxloader {
  background-color: var(--modal-surface);
}

.spinner {
  margin: 50px;
  height: 32px;
  width: 32px;
  animation: rotate 0.8s infinite linear;
  border: 4px solid var(--green-default);
  border-right-color: var(--border);
  border-top-color: var(--border);
  border-radius: 50%;
  opacity: 0.8;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sell-money-input {
  padding-top: 20px;
}
.sell-money-input input {
  height: 40px;
  line-height: 40px;
  border: 0;
  margin-bottom: 10px;
  background-color: var(--modal-surface);
  color: var(--text-primary);
  border-bottom: solid 2px var(--modal-border);
  width: 100%;
  text-align: center;
}
.sell-money-input input::placeholder {
  color: var(--text-tertiary);
}

.sell-modal-detail {
  overflow: auto;
  height: auto;
  padding-bottom: 55px;
  max-height: 100vh;
}

@media screen and (max-width: 320px) {
  .sell-modal-detail {
    max-height: unset;
    height: 100%;
    padding-bottom: 20%;
  }
}

.sell-modal-detail-info {
  font-size: 13px;
  color: #b5b5b5;
  padding: 10px 0;
  margin-top: 5px;
  border-top: solid 1px #ededed;
}

.sell-modal-detail-info svg {
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.modal-prospektus .am-modal-content {
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-prospektus .am-modal-close-x {
  position: relative;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
}

.am-toast-notice {
  position: relative;
  width: 150px;
  padding-bottom: 10px;
}

.verifikasi-box {
  margin: 20px 0;
  margin-left: 20px;
}

.verifikasi-step {
  border-bottom: solid 1px #ededed;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}

.verifikasi-step:nth-child(1) {
  border-top: solid 1px #ededed;
}

.verifikasi-step div:nth-child(1) {
  width: 16px;
  margin-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verifikasi-step div:nth-child(2) {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
}

.verifikasi-step span {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border: solid 1px #ededed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifikasi-step.done span {
  background: #00ab6b;
  border: solid 1px #00ab6b;
}

.home-status {
  position: relative;
  overflow: hidden;
}
.home-status .verifikasi-box + p {
  padding-top: 0;
  text-align: left;
}
.home-status p {
  padding: 0 20px;
  text-align: center;
  padding-top: 20px;
}

.home-status .am-button::before {
  border: 0 !important;
}

.home-status .am-button {
  border-radius: 0;
  border: 0 !important;
  border-top: solid 1px #ededed !important;
}

.closehome {
  background: #ededed;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  position: absolute;
  top: -21px;
  right: -21px;
}
.closehome svg {
  transform: rotate(-45deg);
  position: relative;
  top: 26px;
  right: -13px;
}

.order-notice-img img {
  width: 180px;
  margin: auto;
  display: flex;
}
.am-steps-vertical .am-steps-item-content {
  min-height: initial;
}

.react-code-input input {
  font-size: 16px !important;
}
.am-modal-close-x {
  display: none !important;
}
.bit-back-confirm,
.modal-close-new {
  position: absolute !important;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bit-back-confirm {
  right: auto;
  left: 0;
}

.bit-modal-scroll {
  margin-left: -20px;
  padding: 0 20px;
  width: calc(100% + 40px);
  overflow: auto;
  padding-bottom: 100px;
  max-height: calc(100vh - 50px);
}

.modal-close-new b {
  display: flex;
  background: #7b7b7b;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.am-tab-bar-bar .am-tab-bar-tab-icon {
  height: 16px;
}
.changepin-input-box input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: 0;
  color: var(--text-primary);
  border-bottom: solid 1px var(--border);
}
.changepin-input-box + span {
  padding-top: 10px;
  display: inline-block;
  color: var(--text-primary);
}
.profile-settings-pin .login-register .am-list-item > div {
  border: 0;
}

.am-steps-item-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
.am-icon-md {
  width: 18px;
  height: 18px;
}

.copybutton {
  float: right;
}
.order-confirmed-overflow .am-steps-item-wait {
  display: none;
}
.order-confirmed-overflow .am-steps-item-process + .am-steps-item-wait {
  display: block;
}
.order-confirmed-overflow
  .am-steps-item-process
  + .am-steps-item-wait
  .am-steps-item-content {
  display: none;
}
.order-confirmed-overflow .am-steps-item-process .am-steps-item-description {
  font-size: 13px !important;
  color: var(--text-secondary) !important;
  padding-bottom: 0;
}
.order-confirmed-overflow .am-steps-item-finish .am-steps-item-description {
  font-size: 13px !important;
  color: var(--text-secondary) !important;
  padding-bottom: 0;
}
.order-confirmed-overflow .am-steps-item-tail:after {
  left: -4px;
}

.order-detail-secondary-market-content-container {
  overflow-y: auto;
  padding-bottom: 80px;
}

.portfolio .am-navbar-title {
  display: flex;
  flex-direction: column;
}

.am-navbar-title span:nth-child(1) {
  display: inline-block;
}
.am-navbar-title span:nth-child(2) .bit-category-title-picture {
  display: none;
}

.portfolio .am-navbar-title span:nth-child(2) {
  font-size: 12px !important;
  color: #b5b5b5;
  font-weight: normal;
}

.index-recomend-card .lineborder {
  position: absolute;
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
}

.index-recomend-card .am-list-line {
  padding: 0 15px;
  margin-right: 15px;
  width: calc(100% - 15px);
}

.profiling-card-fragment {
  position: relative;
  overflow: hidden;
}

.profiling-card-fragment .roboSelected {
  position: absolute;
}

.rc-upper-container {
  width: calc(100% - 63px);
}
@media (min-width: 500px) {
  .rc-upper-container {
    width: 100%;
  }
}

.moneymarket .lineborder {
  background: var(--green-default);
}
.fixincome .lineborder {
  background: var(--cobalt-default) !important;
}
.equity .lineborder {
  background: var(--violet-default) !important;
}

[class$='moneymarket'] .content-header,
[class$='fixincome'] .content-header,
[class$='equity'] .content-header {
  height: 55px;
}

.content-header.content-header-onscroll {
  height: 63px;
  transition: height 0.3s;
}

.content-header-onscroll .am-navbar-title {
  margin-top: 16px;
  transition: margin-top 0.3s;
}

.content-header .am-navbar-title {
  transition: margin-top 0.3s;
}

.bibitlogo {
  width: 65px;
  height: 14px;
  position: relative;
  left: 5px;
}
.bibitlogo > img {
  height: 100%;
}
.home .big-xl {
  height: 40px;
}
.home.login .am-navbar,
.non-login .am-navbar {
  background: var(--surface);
  height: 100%;
}

.home.login .content-header,
.non-login .content-header {
  height: 56px;
}

.non-login.content-wrapper {
  background: var(--surface-gray);
}
/* JSP */

.bit-uikit-adjacent-sibling-component.bit-uikit-custom.bigxl {
  height: 40px !important;
}
@media screen and (max-width: 320px) {
  .best-syariah .get-started-banner {
    height: 145px;
  }
}

.best-sbn .am-navbar-light,
.categories-sbn .am-navbar-light,
.compare-categories-sbn .am-navbar-light {
  background: #fceeef;
}
.categories-sbn .content-box > div:first-child {
  background: #fceeef;
}
.best-sbn .content-wrapper,
.categories-sbn .content-wrapper,
.compare-categories-sbn .content-wrapper,
.best-sbn .content-box > div:first-child {
  background-image: linear-gradient(#fceeef, #fceeef);
  background-size: 100% 200px;
}
.compare-categories-sbn .content-box > div:first-child {
  background: #fceeef;
  margin-bottom: 20px;
}

.best-moneymarket .am-navbar-light,
.categories-moneymarket .am-navbar-light,
.compare-categories-moneymarket .am-navbar-light {
  background: #e8f7f1;
}
.categories-moneymarket .content-box > div:first-child {
  background: var(--green-medium);
}
.categories-moneymarket
  .mutualfund-categories--dark
  .content-box
  > div:first-child {
  background: var(--green-medium);
}
.best-moneymarket .content-wrapper,
.compare-categories-moneymarket .content-wrapper,
.best-moneymarket .content-box > div:first-child {
  background: var(--green-medium);
}
.best-moneymarket .content-box,
.best-debt .content-box,
.best-equity .content-box {
  min-height: 100%;
}
.top-reksadana-padding-bottom {
  padding-bottom: 50px;
}
.top-reksadana-padding-bottom.is-logged-in {
  padding-bottom: 100px;
}
@media only screen and (min-width: 1030px) {
  .top-reksadana-padding-bottom {
    padding-bottom: 0px;
  }
  .top-reksadana-padding-bottom.is-logged-in {
    padding-bottom: 30px;
  }
}
.best-moneymarket .get-started-page--dark .content-box > div:first-child {
  background: #103d2d;
}
.compare-categories-moneymarket .content-box > div:first-child {
  background: #e8f7f1;
  margin-bottom: 20px;
}

.categories-instant .am-navbar-light,
.categories-debt .am-navbar-light,
.compare-categories-debt .am-navbar-light,
.categories-instant .content-box > div:first-child {
  background: #fff4da;
}

.bibit-plus.categories-instant .am-navbar-light,
.categories-debt .am-navbar-light,
.compare-categories-debt .am-navbar-light,
.bibit-plus.categories-instant .content-box > div:first-child {
  background: var(--yellow-medium);
}

.sell-confirm-modal-new {
  padding: 0px 0px 0px !important;
}
.sell-confirm-unit {
  display: flex;
}
.sell-confirm-unit > div {
  margin-left: 2px;
}

.best-minimum .am-navbar-light,
.categories-minimum .am-navbar-light {
  background: #ffeffc;
}
.best-minimum .content-wrapper,
.categories-minimum .content-wrapper {
  background-size: 100% 200px;
}
.categories-minimum .content-box > div:first-child {
  background: var(--magenta-medium);
}
.best-indexfund .am-navbar-light,
.categories-indexfund .am-navbar-light {
  background: #f6efe9;
}
.best-indexfund .content-wrapper,
.categories-indexfund .content-wrapper {
  background-size: 100% 200px;
}
.categories-indexfund .content-box > div:first-child {
  background: var(--brown-medium);
}

.best-debt .am-navbar-light,
.categories-debt .am-navbar-light,
.compare-categories-debt .am-navbar-light,
.best-debt .content-box > div:first-child {
  background: var(--cobalt-medium);
}
.best-debt .content-wrapper,
.categories-debt .content-wrapper,
.compare-categories-debt .content-wrapper {
  background-size: 100% 200px;
}
.categories-debt .content-box > div:first-child {
  background: var(--cobalt-medium);
}
.categories-debt .mutualfund-categories--dark .content-box > div:first-child {
  background: var(--cobalt-medium);
}
.compare-categories-debt .content-box > div:first-child {
  background: #eaf2f7;
  margin-bottom: 20px;
}

.best-equity .am-navbar-light,
.categories-equity .am-navbar-light,
.compare-categories-equity .am-navbar-light,
.best-equity .content-box > div:first-child {
  background: var(--violet-medium);
}
.best-equity .content-wrapper,
.categories-equity .content-wrapper,
.compare-categories-equity .content-wrapper {
  background-size: 100% 200px;
}
.categories-equity .content-box > div:first-child {
  background: var(--violet-medium);
}
.categories-equity .mutualfund-categories--dark .content-box > div:first-child {
  background: var(--violet-medium);
}
.compare-categories-equity .content-box > div:first-child {
  background: #f4edf9;
  margin-bottom: 20px;
}

.best-syariah .am-navbar-light,
.categories-syariah .am-navbar-light,
.compare-categories-syariah .am-navbar-light,
.best-syariah .content-box > div:first-child {
  background: var(--olive-medium);
}
.categories-syariah .content-box > div:first-child {
  background: var(--olive-medium);
}
.categories-syariah
  .mutualfund-categories--dark
  .content-box
  > div:first-child {
  background: var(--olive-medium);
}
.best-syariah .content-wrapper,
.categories-syariah .content-wrapper,
.compare-categories-syariah .content-wrapper {
  background-size: 100% 200px;
}
.compare-categories-syariah .content-box > div:first-child {
  background: #f4f7ec;
  margin-bottom: 20px;
}

.categories-others .am-navbar-light,
.compare-categories-others .am-navbar-light {
  background: #edf6fb;
}
.categories-others .content-box > div:first-child {
  background: var(--blue-medium);
}
.categories-others .mutualfund-categories--dark .content-box > div:first-child {
  background: var(--blue-medium);
}
.categories-others .content-wrapper,
.compare-categories-others .content-wrapper {
  background-size: 100% 200px;
}
.compare-categories-others .content-box > div:first-child {
  background: #edf6fb;
  margin-bottom: 20px;
}

.category-benchmark .am-navbar-light,
.category-benchmark .am-navbar-light,
.compare-categories-benchmark .am-navbar-light {
  background: #fbe3df;
}
.category-benchmark .content-wrapper,
.category-benchmark .content-wrapper,
.compare-categories-benchmark .content-wrapper {
  background-image: linear-gradient(#fbe3df, #fbe3df);
  background-size: 100% 200px;
}
.compare-categories-benchmark .content-box > div:first-child {
  background: #fbe3df;
  margin-bottom: 20px;
}

.category-benchmark .content-box {
  background: transparent !important;
}
.category-benchmark {
  background: #fbe3df !important;
}

.categories-usd .am-navbar-light {
  background: rgba(235, 238, 244, 255);
}
.categories-usd .content-box > div:first-child {
  background-color: var(--navy-medium);
}

/* Fix issues for iPhone 5/SE */

@media screen and (max-width: 320px) {
  .best-moneymarket .content-wrapper,
  .categories-moneymarket .content-wrapper,
  .best-debt .content-wrapper,
  .categories-debt .content-wrapper,
  .best-equity .content-wrapper,
  .categories-equity .content-wrapper,
  .best-minimum .content-wrapper,
  .categories-minimum .content-wrapper,
  .best-syariah .content-wrapper,
  .categories-syariah .content-wrapper {
    background-size: 100%;
  }
}

.bestofcontent {
  background: var(--surface);
  padding-bottom: 20px;
  height: auto;
}

.replaceimage .oldimage {
  display: none;
}

.am-input-disabled .am-list-line {
  border-bottom: 0 !important;
}
.am-input-disabled input {
  color: var(--text-primary) !important;
}
.am-input-disabled + .kettxt {
  border-top: 0 !important;
}

/* JSP */

.loaderbar + .loaderbarhack {
  display: none;
}
.loaderbarhack {
  height: 2px;
  width: 100%;
  background: #00ab6b;
  margin-bottom: 20px;
}

.loaderbar {
  height: 2px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--surface-gray);
  border-radius: 2px 2px 0 0;
  margin-top: 0;
  transition: 0.3s ease all border-top-left;
}

.sell-modal .loaderbar,
.portfolio-detail-modal .loaderbar,
.bit-exitload-modal-content .loaderbar {
  background-color: var(--modal-surface-gray);
}

.loaderbar:before {
  height: 2px;
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  background-color: #00ab6b;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(0.76);
  }
  to {
    transform: scale(1);
  }
}

/*--------------------------------------------------*/

.black {
  color: #333;
}

.marginBottom .am-card {
  margin-bottom: 20px;
}

.outerwrap > * > * > .am-tabs-tab-bar-wrap {
  border-width: 2px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.has-tab > * > .am-tabs {
  padding-bottom: 50px;
}

.content-box {
  -js-display: flex;
  display: flex;
  flex-direction: column;
}

.cara-pembelian .content-box,
.cara-pembelian .am-navbar,
.cara-pembelian-robo .content-box,
.cara-pembelian-robo .am-navbar,
.cara-pembelian-single .content-box,
.cara-pembelian-single .am-navbar,
.cara-penjualan .content-box,
.cara-penjualan .am-navbar,
.cara-kerja-robo .content-box,
.cara-kerja-robo .am-navbar {
  background: var(--surface);
}

@media only screen and (min-width: 1030px) {
  .cara-pembelian .content-box,
  .cara-pembelian .am-navbar,
  .cara-pembelian-robo .content-box,
  .cara-pembelian-robo .am-navbar,
  .cara-pembelian-single .content-box,
  .cara-pembelian-single .am-navbar,
  .cara-penjualan .content-box,
  .cara-penjualan .am-navbar,
  .cara-kerja-robo .content-box,
  .cara-kerja-robo .am-navbar {
    background: var(--surface-gray);
  }
  .content-wrapper.newPin .am-navbar {
    background-color: var(--surface-gray);
    color: var(--text-primary);
  }
  .order-finish .content-header .am-navbar {
    background-color: var(--surface-gray);
  }
  .content-wrapper.newPin .am-navbar .am-navbar-right .icon-support-wrapper {
    margin-right: 0;
  }
}

.content-box > .bit-uikit-custom,
.content-box .file-box-wrap {
  flex: none;
}

.reksadana-color-category,
.reksadana-sell-category {
  width: 5px;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.reksadana-color-category.pasar-saham {
  background-color: var(--violet-default);
}
.reksadana-color-category.pasar-obligasi {
  background-color: var(--navy-default);
}
.reksadana-color-category.pasar-uang {
  background-color: var(--green-default);
}
.reksadana-color-category.pasar-lainnya {
  background: #5baedd;
}

.removeAllPadding {
  padding: 0px;
}

.addingPaddingLR {
  padding: 0px 20px;
}

.addingPaddingTopBottom {
  padding: 20px 0px;
}

.allPadding {
  padding: 15px 20px;
}

.real-red {
  color: #ee4a49;
}

.am-checkbox-inner,
.am-checkbox {
  width: 17px;
  height: 17px;
}
.am-checkbox-inner {
  top: 2px;
  right: -2px;
}
.am-checkbox-inner:after {
  width: 3px;
  height: 7px;
}

.am-checkbox-checked .am-checkbox-inner {
  background: #00ab6b !important;
  border-color: #00ab6b !important;
}

.am-list-body div:not(:last-child) .am-list-line {
  border: 0;
}
.am-list-body {
  border: 0;
  background-color: var(--surface);
}

.bb + .bb {
  border-top: solid 1px #ededed;
  padding-bottom: 10px;
  padding-top: 10px;
}
.bb > div {
  display: flex;
  align-items: center;
}
.bb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}
.b1 {
  margin-right: 10px;
}

.b1 span {
  border-radius: 2px;
  width: 65px;
  display: block;
  height: 40px;
  background: #f5f5f5;
  text-align: center;
}
.b1 span svg {
  color: #ccc;
  height: 40px;
  width: 18px;
}

.b1 img {
  height: 40px;
}
.bb-title {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
  margin-top: 10px;
}

.bb-wrap {
  border: solid 1px #ededed;
  border-radius: 4px;
  margin-bottom: 10px;
}

.KTP .am-tabs-tab-bar-wrap {
  display: none;
}
.EDD .am-tabs-tab-bar-wrap {
  display: none;
}

.modal-radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-radius .am-modal-content {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.am-image-picker-item-remove {
  display: none;
}

.MsgErrorReg svg {
  margin-right: 3px;
}
.MsgErrorReg {
  color: #ee4a49;
  border-top: solid 1px #ededed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
}
.kettxt + .MsgErrorReg {
  border-top: 0;
}
.badge-porto-pending-wrap {
  display: flex;
}

.am-checkbox-wrapper:after {
  content: '';
  clear: both;
  display: table;
}

.birthday-picker {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.birthday-picker .am-picker-popup-title {
  color: var(--text-primary);
}

.register-support {
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: solid 1px #ededed;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-support .iconSupportWrap {
  border: 0;
}

.discoverMoreBtn {
  height: 40px;
  border-radius: 4px;
  border: solid 1px var(--border);
  color: #00ab6b;
  background: var(--surface);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 0;
}

.best-moneymarket .discoverMoreBtn {
  color: var(--green-default);
  border-color: var(--green-default);
}

.best-debt .discoverMoreBtn {
  color: var(--cobalt-default);
  border-color: var(--cobalt-default);
}

.best-equity .discoverMoreBtn {
  color: var(--violet-default);
  border-color: var(--violet-default);
}

.best-syariah .discoverMoreBtn {
  color: var(--olive-default);
  border-color: var(--olive-default);
}

.bit-custom-discover-btn {
  margin: 20px 20px 0;
}

select {
  appearance: none;
  border: 0;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  font-size: 16px;
  padding: 0 20px;
  background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im0xMjEuMywzNC42Yy0xLjYtMS42LTQuMi0xLjYtNS44LDBsLTUxLDUxLjEtNTEuMS01MS4xYy0xLjYtMS42LTQuMi0xLjYtNS44LDAtMS42LDEuNi0xLjYsNC4yIDAsNS44bDUzLjksNTMuOWMwLjgsMC44IDEuOCwxLjIgMi45LDEuMiAxLDAgMi4xLTAuNCAyLjktMS4ybDUzLjktNTMuOWMxLjctMS42IDEuNy00LjIgMC4xLTUuOHoiIGZpbGw9IiM5NDk0OTQiLz4KICA8L2c+Cjwvc3ZnPgo=);
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: auto 12px;
  width: 100%;
}

select + select {
  border-top: solid 1px #ededed;
}

.terapkanWrap select:nth-child(1) {
  border-radius: 5px 5px 0 0;
}
.terapkanWrap select:last-child {
  border-radius: 5px 5px 0 0;
}

.selectWrap {
  width: calc(100% - 40px);
  margin: 20px;
  border: solid 1px #ededed;
  overflow: hidden;
  border-radius: 4px;
}
.terapkan {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00ab6b;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
}

.terapkan button {
  font-weight: 700;
}

.terapkanWrap {
  padding: 20px 0;
}

.terapkanWrap b {
  font-size: 16px;
  text-align: left;
  display: block;
  padding: 0 20px 0 20px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-porto-recurring {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
}

.errorspan {
  clear: both;
}

.bit-register-forgot-phone-error {
  display: flex;
  align-items: center;
  color: #ff6666;
  margin-bottom: 18px;
  padding: 5px 0;
  position: relative;
  font-size: 12px;
}

.bit-register-forgot-phone-error > img {
  margin-right: 6px;
}

@media only screen and (max-width: 767px) {
  .bit-register-check-referral-wrapper {
    margin-bottom: 150px;
  }
}

.moreOption {
  text-align: center;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #9343c8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.moreOption span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.moreOption span b {
  background: #9343c8;
  width: 10px;
  height: 1px;
}

.moreOption span b:nth-child(2) {
  transform: rotate(90deg);
  position: absolute;
}

.dualButton {
  position: relative;
  width: 100%;
  padding: 20px;
  padding-top: 0;
  background: #fff;
}

@media screen and (max-width: 720px) {
  .dualButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background: #fff;
  }
}

.buyInputSuggest::-webkit-scrollbar {
  height: 1px;
}

.buyInputSuggest {
  overflow-x: auto !important;
  display: flex !important;
  margin-bottom: 20px;
  overflow-y: hidden;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  height: 100%;
  width: 100%;
}

.buyInputSuggest span {
  color: var(--green-default);
  text-align: center;
  padding: 0 10px;
  margin: 0px 10px 0 0px;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
  border: solid 1px var(--border);
  font-size: 13px;
  display: block;
}

.addressBox {
  font-size: 16px;
  margin-top: 7px;
  padding-bottom: 7px;
  /* border-bottom: solid 1px #ededed; */
}
.description-header {
  padding: 15px 20px;
  background: var(--cobalt-low);
  border-radius: 5px;
  margin: 15px 0;
  color: var(--cobalt-default);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description-header svg {
  margin-right: 20px;
}

.newInputPin {
  font-family: monospace;
  border: 0;
  border-bottom: solid 1px #ededed;
  text-align: center;
  height: 42px;
  font-size: 16px;
  box-sizing: border-box;
  color: black;
  background-color: white;
  letter-spacing: 20px;
}

.newInputPinBox {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.newInputPinBoxChangePhone {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newInputPinBoxChangePhone .bit-input-verify-top {
  margin-top: 16px;
}
.newInputPinBox-countdown-timer {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  color: #bbb;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
}

.am-tab-bar-item {
  position: relative;
}

@keyframes spaceboots {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake2 {
  -webkit-animation-name: spaceboots;
  animation-name: spaceboots;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes show {
  to {
    opacity: 100;
  }
}

.animate-show {
  opacity: 0;
  animation: show 0.35s forwards;
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.shakebutton {
  position: relative;
}
.shakebutton img {
  position: absolute;
  z-index: 1000000;
  left: -20px;
  width: 200px;
  bottom: 80px;
}
.shakebutton button {
  animation: shakeAnim 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-iteration-count: infinite;
}

@keyframes shakeAnim {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.modalTitle {
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  border-bottom: solid 1px #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #00ab6b;
}

.modalContent {
  padding: 20px 0;
}

.modalInput input {
  height: 40px;
  border: 0;
  width: calc(100% - 30px);
  background: transparent;
}

.modalInput-error {
  padding-top: 5px;
  font-size: 13px;
  color: #969999;
  display: block;
}

.modalButton {
  font-size: 15px;
  color: #fff;
  background: #00ab6b;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
}
.modalButton.disabled {
  background-color: #99ddc4;
}
.bit-modal-bank-picker .am-navbar-right {
  color: #00ab6b;
  font-size: 13px;
}
.bit-modal-bank-picker
  .modal-content
  > div:nth-child(2)
  .am-search
  .am-search-input {
  border: 0 !important;
  border-radius: 4px;
}
.bit-modal-bank-picker .modal-content > div:nth-child(2) .bank-picker-header {
  position: sticky;
  top: -1px;
  background-color: #fff;
  z-index: 1;
  min-height: 135px;
}

.bit-modal-bank-picker .am-search {
  background-color: #fff;
  height: 60px;
}
.bit-modal-bank-picker .am-search-input,
.bit-modal-bank-picker .am-search-synthetic-ph {
  height: 50px !important;
  line-height: 50px !important;
}

.bit-modal-bank-picker .am-search .am-search-input input[type='search'] {
  height: 48px;
  line-height: 48px;
  padding: 0 45px;
}
.bit-modal-bank-picker .am-search .am-search-input input[type='search']:focus {
  border: 2px solid #00ab6b;
}
.bit-modal-bank-picker .am-search .am-search-input .am-search-synthetic-ph {
  padding-left: 45px !important;
  text-align: left;
}
.bit-modal-bank-picker .modal-content > div:nth-child(2) .am-search {
  overflow: unset;
  height: 52px;
  padding: 0px !important;
}
.bit-modal-bank-picker
  .modal-content
  > div:nth-child(2)
  .am-search
  .am-search-input {
  border: 1px solid #ededed;
  border-radius: 4px;
}
.bit-modal-bank-picker
  .modal-content
  > div:nth-child(2)
  .am-search
  .am-search-cancel {
  display: none;
}

.bit-modal-bank-picker
  .modal-content
  > div:nth-child(2)
  .bank-picker
  .am-list
  .am-list-body::before {
  display: none;
}
.bit-modal-bank-picker .modal-content > div:nth-child(2) .bank-picker .am-list {
  overflow-y: unset !important;
}
.bit-modal-bank-picker .modal-content > div:nth-child(2) .top-wrapper {
  position: fixed;
  top: 60px;
  z-index: 999;
  padding-right: 40px;
  background: #fff;
  width: 100%;
}
.bit-modal-bank-picker .am-search .am-search-input .am-search-clear {
  top: 11px;
  right: 9px;
}
.bit-modal-bank-picker
  .am-search
  .am-search-input
  .am-search-synthetic-ph-icon {
  top: 18px;
  left: 18px;
}
.bit-modal-bank-picker .bank-information {
  background-color: var(--modal-green-low);
  color: var(--text-primary);
  border-radius: 4px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
}

/* Handle UIKIT-V1 modal on bank picker */
.bit-modal-bank-picker .modal-content {
  padding-bottom: 0;
  height: 100%;
}

.bit-green-background {
  background-color: #f2fbf8;
}

.bit-navbar-bg-transition {
  -webkit-transition: background-color 0ms linear;
  -ms-transition: background-color 0ms linear;
  transition: background-color 0ms linear;
}

.bit-modal-bank-picker .bank-information svg {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.bit-modal-bank-picker .bank-information svg rect {
  fill: var(--modal-green-high);
}

.btn-disabled {
  opacity: 0.5;
}

.am-picker-popup {
  background-color: transparent;
}
@media (min-width: 1030px) {
  div.content-wrapper.mutualfund-categories--dark {
    background: var(--surface);
  }
  .wrapper-quickaccess-portfolio {
    background-color: transparent;
    padding: 25px 0px 0px 0px;
    z-index: 99;
    position: relative;
  }
  .content-wrapper.login-register .am-navbar {
    background: var(--surface-gray) !important;
  }
  .login-register > .content-box {
    background-color: var(--surface-gray);
  }
  .home.login .am-navbar {
    background-color: var(--surface-gray);
  }
  .profiling-select .am-navbar {
    background-color: var(--surface-gray) !important;
  }
  .notifications .am-navbar {
    background-color: var(--surface-gray) !important;
  }
  .am-picker-popup {
    left: 50%;
    margin-left: -384px;
    width: 768px;
  }
  .sell-modal-detail {
    max-height: 525px;
  }
  .modalButton.disabled {
    border: 1px solid #99ddc4;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .categories-usd .content-wrapper,
  .categories-usd .content-wrapper {
    background: transparent;
  }
  .categories-usd .am-navbar-light {
    background: transparent !important;
  }
  .categories-debt .get-started-page .content-box {
    height: auto !important;
  }
  .categories-equity .get-started-page .content-box {
    height: auto !important;
  }
  .categories-syariah .get-started-page .content-box {
    height: auto !important;
  }
  .categories-others .get-started-page .content-box {
    height: auto !important;
  }
  .categories-minimum .get-started-page .content-box {
    height: auto !important;
  }
  .categories-indexfund .get-started-page .content-box {
    height: auto !important;
  }
  .categories-usd .get-started-page .content-box {
    height: auto !important;
  }
  .categories-instant .get-started-page .content-box {
    height: auto !important;
  }
  /* Set navbar color to background color in desktop layout */
  .portfolio.content-wrapper .content-header {
    background-color: var(--surface-gray) !important;
  }
}
@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.load-bar-container {
  padding: 20px;
}
.load-bar-toast {
  border: 1px solid #00ab6b;
  border-radius: 5px;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.load-bar-toast div {
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  height: 14px;
  width: 70%;
}
@keyframes toast-bar-move {
  0% {
    width: 0;
  }
  20% {
    width: 24%;
  }
  45% {
    width: 24%;
  }
  80% {
    width: 70%;
  }
  100% {
    width: 70%;
  }
}
.bar-width-full {
  width: 100% !important;
}

/* --- New Sorting --- */
.bit-sorting-row {
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin: 16px 0 22px;
}
.sorting-items {
  align-items: center;
  display: flex;
  width: 100%;
  overflow-y: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow-x: scroll;
  padding: 2px 0;
}
.sorting-items div {
  background-color: rgba(0, 171, 107, 0.1);
  border: solid 1px #00ab6b !important;
  border-radius: 20px;
  margin: 0 4px;
  padding: 4px 14px;
  text-align: center;
}
.sorting-items div:first-child {
  margin-left: 0;
}

.im-35-logo {
  display: block;
  height: 35px;
  width: auto;
  margin-right: 16px;
  max-width: 35px;
}

.sorting-items + div + div {
  align-items: center;
  display: flex;
  font-weight: bold;
  margin-right: -2px;
}
.sorting-items + div + div img {
  margin-left: 5px;
}
img.bit-sort-arr-high {
  transform: rotate(0deg);
  margin-left: 8px;
}
img.bit-sort-arr-low {
  transform: rotate(180deg);
  margin-left: 8px;
}
.bit-close-filter-sorting-components {
  align-items: center;
  background: #ccc;
  border: 0 !important;
  border-radius: 50%;
  display: flex;
  height: 12px;
  margin-right: auto;
  justify-content: center;
  padding: 0;
  transform: translateX(4px);
  width: 12px;
}
.sorting-components-white-space {
  visibility: hidden;
  padding: 0 20px !important;
}
.bit-right-filter {
  display: flex;
  position: relative;
  right: 0;
  background-color: white;
  padding: 5px;
  box-shadow: -16px 4px 16px rgb(255 255 255 / 75%);
  font-weight: bold;
}

@media (min-width: 1030px) {
  .bit-right-filter {
    background-color: transparent;
  }
}

@media screen and (max-width: 340px) {
  .sorting-items div {
    padding: 4px 12px;
  }
}

@keyframes ctl {
  0% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0px);
  }
}

.animation-right-left .content-box {
  -webkit-animation: ctl 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ctl 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animation-left-right .content-box {
  -webkit-animation: ctl-left 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ctl-left 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes ctl-left {
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0px);
  }
}

.twinsButton {
  display: flex;
}

.twinsButton .am-button::before {
  border: 0 !important;
}
.twinsButton .am-button {
  width: calc(50% - 1px);
  border: solid 1px #e4e4e4 !important;
  border-radius: 0 !important;
  font-size: 14px;
}
.twinsButton .am-button + .am-button {
  border-left: 0 !important;
}

.twinsButton .am-button:first-child {
  border-radius: 5px 0 0 5px !important;
}

.twinsButton .am-button:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.modalTitle {
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  border-bottom: solid 1px #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #00ab6b;
}

.modalContent {
  padding: 20px 0;
}

.modalInput input {
  height: 40px;
  border: 0;
  width: calc(100% - 30px);
  background: transparent;
}

.modalInput-error {
  padding-top: 5px;
  font-size: 13px;
  color: #969999;
  display: block;
}

.modalButton {
  font-size: 15px;
  color: #fff;
  background: #00ab6b;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
}
.modalButton.disabled {
  background-color: #99ddc4;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.bar-width-full {
  width: 100% !important;
}
.bit-nomargin-bottom {
  margin-bottom: 0;
}
.bit-nopadding-bottom {
  padding-bottom: 0;
}
.bit-nopadding-left {
  padding-left: 0 !important;
}
.nomargin-bottom {
  margin-bottom: 0 !important;
}
.am-tab-bar-bar-hidden-bottom {
  display: none !important;
}
.bit-nomargin-top {
  margin-top: 0px;
}
.bit-reksa-row img {
  display: block;
  margin: 10px auto;
  width: 40px;
}

.bit-reksa-wrap-in > p:nth-child(2) {
  color: var(--text-secondary);
}

@media screen and (max-width: 560px) {
  .bit-reksa-row img {
    width: 40px;
  }
}

h3 {
  font-size: 16px;
}
.referral-banner {
  display: none;
}
.simulIMG {
  width: 75px;
}

.bit-text-center {
  text-align: center;
}

span.animated-background {
  display: inline-block;
}

@keyframes example {
  from {
    opacity: 0;
    filter: blur(2px);
  }
  to {
    opacity: 1;
    filter: blur(0px);
  }
}

.modalHeader {
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.moreBulletTheme.styleIcon {
  display: none;
}

/* THIS IS FOR HIDING RECAPTCHA BADGE */
.grecaptcha-badge {
  visibility: collapse !important;
}

.content-wrapper.newPin .content-box {
  background-color: var(--surface);
  position: relative;
}

.modalClose {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50px;
  margin-right: 20px;
}

.paynote-1 {
  color: #fff;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
  margin-right: 6px;
  font-weight: bold;
}
.paynote-2 {
  font-weight: bold;
  font-size: 14px;
}
.paynote-3 {
  display: block;
  padding-top: 5px;
  font-size: 13px;
}

.user-bank-account {
  margin-left: 6px;
}

.bank-icon {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
}

.sbn-bank-icon {
  max-width: 85px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
}

.bca {
  background-color: #0a5fa5;
}
.mandiri {
  background-color: #053461;
}
.cimb {
  background-color: #e72d22;
}
.maybank {
  background-color: #f7c804;
}
.permata {
  background-color: #48aa3a;
}
.dbs {
  background-color: #c60000;
}
.bni {
  background-color: #005f7c;
}
.bri {
  background-color: #004f97;
}
.ocbc {
  background-color: #f20722;
}
.btpn {
  background-color: #ed7e1f;
}
.panin {
  background-color: #1c4494;
}
.citibank {
  background-color: #016fac;
}

.profiling-content-box-wrap {
  margin-bottom: 50px;
}

.black-bibit {
  color: #333;
}
.blue-bibit {
  color: var(--cobalt-default) !important;
}
.green-bibit {
  color: var(--green-default);
}
.red-bibit {
  color: var(--red-default);
}
.purple-bibit {
  color: var(--violet-default);
}

.border-left-green {
  border-left: 4px solid var(--green-default) !important;
}
.border-left-blue {
  border-left: 4px solid var(--cobalt-default) !important;
}
.border-left-purple {
  border-left: 4px solid var(--violet-default) !important;
}
.border-left-lime {
  border-left: 4px solid #93b22b !important;
}
.border-left-lightblue {
  border-left: 4px solid #5baedd !important;
}

.font-bold {
  font-weight: bold;
}
.font-normal {
  font-weight: normal !important;
}
.border-bottom {
  border-bottom: solid 1px #ededed;
}
.border-top {
  border-top: solid 1px var(--border);
}
.border-color-grey {
  border: 1px solid #ededed;
}

.flex-col {
  display: flex;
}
.align-center {
  align-items: center;
}
.items-start {
  align-items: flex-start !important;
}
.space-between {
  justify-content: space-between;
}
.gray-text {
  color: var(--text-secondary);
}
.inline-flex {
  display: inline-flex;
}

.underline {
  text-decoration: underline;
}

.underline.green50 {
  text-decoration-color: var(--green-default);
}

.underline.red50 {
  text-decoration-color: var(--green-default);
}

.text-right {
  text-align: right;
}
.width-100-percent {
  width: 100%;
}
.width-50-percent {
  width: 50%;
}

.border-grey {
  border: 1px solid var(--border);
}

.border-green {
  border: 1px solid #8dd4b6;
}

.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-28 {
  font-size: 28px;
}
.font-32 {
  font-size: 32px;
}
.font-34 {
  font-size: 34px;
}
.font-36 {
  font-size: 36px;
}
.font-38 {
  font-size: 38px;
}
.font-40 {
  font-size: 40px;
}
.font-42 {
  font-size: 42px;
}
.font-44 {
  font-size: 44px;
}
.font-46 {
  font-size: 46px;
}
.font-48 {
  font-size: 48px;
}
.font-50 {
  font-size: 50px;
}

.nomargin {
  margin: 0 !important;
}
.-margin-top-4 {
  margin-top: -4px;
}
.margin-top-4 {
  margin-top: 4px;
}
.margin-top-8 {
  margin-top: 8px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-12 {
  margin-top: 12px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-top-24 {
  margin-top: 24px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-top-23 {
  margin-top: 23px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-left-4 {
  margin-left: 4px;
}
.margin-left-8 {
  margin-left: 8px;
}
.margin-left-12 {
  margin-left: 12px;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-left-20 {
  margin-left: 20px;
}
.relative {
  position: relative;
}

.visibility-hidden {
  visibility: hidden;
}
.gray-separator {
  background: #f5f6f7;
  height: 5px;
  margin-bottom: 18px;
  margin-left: -20px;
  width: calc(100% + 42px);
}
.bit-noborder {
  border: 0 !important;
}
.bit-align-left {
  text-align: left;
}
.margin-left-right-20 {
  margin: 0 20px;
}
.btnWMargin {
  margin: 20px 0;
}
.btnNoWrap {
  white-space: nowrap;
}
.menuHeightComp {
  width: 100%;
  height: 50px;
}
.change-pin.has-tab .am-tabs-tab-bar-wrap {
  display: none;
}
.profiling .am-tabs-tab-bar-wrap {
  display: none;
}
.has-tab.profiling > * > .am-tabs {
  padding-bottom: 0;
}
.has-tab.change-pin > * > .am-tabs {
  padding-bottom: 0;
}
/* OrderUnconfirmedCard */
.reksatypebar {
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
}
.reksatypebar.saham {
  background-color: var(--violet-default);
}
.reksatypebar.pendapatan-tetap {
  background-color: var(--cobalt-default);
}
.reksatypebar.pasar-uang {
  background-color: var(--green-default);
}
.ww {
  padding: 0 5px;
}
.w1 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.w2 {
  font-weight: bold;
  font-size: 15px;
}
.w3 {
  margin-bottom: 15px;
}
.rejectedMsg p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.rejectedMsg b {
  color: #ee4a49;
}
.am-steps-item-error .am-steps-item-icon > .am-steps-icon {
  color: #ee4a49;
}
.am-steps-item-error .am-steps-item-title {
  color: black;
}
.many {
  margin: 20px 0;
}
.many p {
  text-align: left;
  margin: 0 !important;
  padding: 0 20px 0 10px;
}
.many li + li {
  margin-top: 20px;
}
.many li p + p {
  padding-top: 5px;
}
.many li p:nth-child(1) {
  font-weight: bold;
}
.many li {
  list-style-type: circle;
}
ul.one {
  margin: 0;
  padding: 0;
}
.one p {
  text-align: left;
  margin: 0 !important;
  padding: 0 20px;
}
.one li + li {
  margin-top: 20px;
}
.one li p + p {
  padding-top: 0;
}
.one li p:nth-child(1) {
  font-weight: bold;
}
.one li {
  list-style-type: none;
}
.one li:last-child {
  margin-bottom: 20px;
}
.rejectedBox .one p {
  padding-left: 50px;
  padding-top: 10px;
}
.rejectedBox .one li p + p {
  padding-top: 0;
}
.content-wrapper.home .content-box,
.content-wrapper.portfolio .content-box,
.content-wrapper.portfolio.shared-portfolio .content-box {
  background: none;
}
.content-wrapper.portfolio .puller-box {
  background: var(--surface) !important;
  height: auto;
}
.content-container-premium .content-box {
  animation: none;
  -webkit-animation: none;
}
.content-wrapper.home .home-puller-background-premium {
  -webkit-animation: ctl-home 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ctl-home 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes ctl-home {
  0% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes ctl-left-home {
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0px);
  }
}

.scrollable {
  overflow: auto !important;
}

.am-card.card-no-min-heigth {
  min-height: unset !important;
}

.bit-verification-form .content-box {
  background-color: var(--surface);
}

.bit-verification-form .am-navbar {
  background-color: var(--surface);
}

@media only screen and (min-width: 1030px) {
  .content-wrapper.portfolio .puller-box {
    background: var(--surface-gray) !important;
  }

  .loader-shimmer-chart {
    background-position-y: center;
    background-position-x: center;
    height: 300px;
    background-size: 53%;
  }
  .bit-verification-form .am-navbar {
    background-color: var(--surface-gray);
  }

  .bit-verification-form .newInputPinBox {
    margin: 0 auto;
    width: 370px;
  }
  .bit-verification-form .errorspan {
    margin: 0 auto;
    text-align: center;
    width: 370px;
  }
  .bit-modal-bank-picker .am-navbar-right {
    cursor: pointer;
    padding-right: 15px;
  }
  .am-navbar-right {
    padding-right: 0;
  }
  .empty-state {
    height: calc(100vh - 205px);
  }
  button {
    cursor: pointer;
  }
  .router {
    max-height: 100%;
    width: 768px;
    background: transparent;
  }
  body {
    max-height: 100%;
    width: 768px;
    margin: auto;
  }
  .formobile {
    display: none;
  }
  .forweb {
    display: block;
  }
  body,
  html {
    background: var(--surface-gray) !important;
  }

  .am-navbar-light {
    background: var(--surface-gray);
  }
  .content-header {
    height: 79px !important;
  }
  .am-navbar {
    height: 80px !important;
    width: 100%;
  }
  .am-navbar-left {
    padding-left: 0;
  }
  .am-modal-body .am-navbar-left {
    padding-left: 20px;
  }
  .rightTopIcon {
    right: -10px;
  }
  .rightTopIcon.watchlist {
    padding-right: 20px;
  }
  .bibitlogo {
    width: 75px;
    height: 16px;
  }

  .am-card {
    min-height: unset;
  }
  .content-box {
    background: none;
    padding-bottom: 30px;
  }
  .content-wrapper {
    background: transparent;
  }
  .content-wrapper.login-register {
    background: transparent;
  }
  .login-register .content-box > div {
    background: var(--surface);
    border: solid 1px var(--border);
    padding: 20px 20px 20px 20px !important;
  }
  .login-register.registration-form .content-box > div {
    background: none !important;
    border: none !important;
  }

  .portfolio .content-wrapper {
    background: transparent;
  }
  .logged-in .content-wrapper {
    background: transparent;
  }
  .content-wrapper.portfolio,
  .content-wrapper.portfolio.shared-portfolio,
  .content-wrapper.portfolio.shared-portfolio.shared-portfolio-dark {
    background: none !important;
  }
  .reksaListPageWrap {
    background: none;
  }
  .am-navbar-title,
  .am-navbar-title span,
  .am-navbar-light .am-navbar-title {
    font-size: 20px !important;
  }
  .reksadana .am-navbar-title > div {
    background: none;
  }
  .reksadana .am-navbar-title > div:after {
    background: none;
  }

  [class$='moneymarket'] .content-header,
  [class$='fixincome'] .content-header,
  [class$='equity'] .content-header {
    height: unset;
  }

  .best-moneymarket .am-navbar-light,
  .categories-moneymarket .am-navbar-light,
  .best-moneymarket .content-wrapper,
  .categories-moneymarket .content-wrapper,
  .categories-instant .am-navbar-light,
  .best-minimum .am-navbar-light,
  .categories-minimum .am-navbar-light,
  .best-minimum .content-wrapper,
  .categories-minimum .content-wrapper,
  .best-indexfund .am-navbar-light,
  .categories-indexfund .am-navbar-light,
  .best-indexfund .content-wrapper,
  .categories-indexfund .content-wrapper,
  .best-debt .am-navbar-light,
  .categories-debt .am-navbar-light,
  .best-debt .content-wrapper,
  .categories-debt .content-wrapper,
  .best-equity .am-navbar-light,
  .categories-equity .am-navbar-light,
  .best-equity .content-wrapper,
  .categories-equity .content-wrapper,
  .best-syariah .am-navbar-light,
  .categories-syariah .am-navbar-light,
  .best-syariah .content-wrapper,
  .categories-syariah .content-wrapper,
  .categories-others .am-navbar-light,
  .categories-others .content-wrapper,
  .categories-instant .am-navbar-light,
  .categories-instant .content-wrapper {
    background: none;
  }

  .empty-state-portfolio {
    background: none;
  }
  .boxloader {
    background: none !important;
  }
  .am-tab-bar-bar {
    height: 60px;
    background: white !important;
    border-radius: 5px;
    border: solid 1px #ededed !important;
    transform: translateY(-20px);
    /* box-shadow: 0 -1px 2px rgba(0,0,0,0.07); */
  }
  .am-tabs-default-bar {
    background: none !important;
  }
  .am-tabs-default-bar-tab {
    font-weight: bold;
  }
  .am-list-body,
  .am-list-item {
    background: none;
  }

  .am-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
  }
  .am-modal-popup {
    position: relative;
    width: auto;
    min-width: 400px;
    max-width: 560px;
  }
  .am-modal-content,
  .bc-modal-body .am-modal-content {
    border-radius: 8px !important;
  }
  .bestofcontent {
    background: none;
  }

  a,
  .modal-close-new,
  .terapkan,
  .terapkanWrap b i,
  .rightTopIcon,
  .primary-btn,
  .am-accordion-header,
  .am-tab-bar-bar .am-tab-bar-tab,
  .am-tabs-default-bar-top .am-tabs-default-bar-tab,
  .bit-filter-button-reset,
  .sorting-items + div + div {
    cursor: pointer;
  }

  .am-tab-bar-bar .am-tab-bar-tab {
    cursor: pointer;
    width: 50px;
    height: 50px;
    flex: unset;
    border-radius: 5px;
    transition: 0.3s ease-in;
  }
  .am-tab-bar-bar .am-tab-bar-tab:hover {
    background: #f0f0f0;
  }
  .backBigger {
    background: var(--border);
    padding: 5px;
    border-radius: 50px;
    width: auto;
    height: auto;
    left: unset;
  }
  .bit-modal-close,
  .mnmn,
  .primary-btn,
  .backBigger,
  .buyInputSuggest span,
  .sorting-items div,
  .bit-close-filter {
    cursor: pointer;
  }
  .primary-btn-fixed-wrap {
    background: none;
    border-top: 0;
  }
  .content-wrapper.newPin .content-box {
    border: solid 1px var(--border);
    height: 100% !important;
    padding-bottom: 50px !important;
    background-color: var(--surface) !important;
  }

  .explore-stock .bit-container-padding-horizontal,
  .explore-watchlist-indeks .bit-container-padding-horizontal,
  .explore-watchlist-stock .bit-container-padding-horizontal {
    background-color: var(--surface-gray);
  }

  .order-confirmed-overflow .bit-container-padding-horizontal {
    padding: 0 20px;
  }

  .order-confirm .bit-container-padding-horizontal,
  .am-modal-body .bit-container-padding-horizontal {
    padding: 0 20px 20px 20px;
  }

  #root,
  .App,
  .router,
  .content-box {
    height: auto !important;
  }
  .outerwrap > * > * > .am-tabs-tab-bar-wrap {
    position: fixed;
    z-index: 998;
    width: 768px;
    margin: auto;
  }
  .has-tab .am-tabs-tab-bar-wrap:after {
    content: '';
    display: block;
    height: 20px;
    background: #f9f9f9;
    position: fixed;
    bottom: 0;
    width: 768px;
  }
  .content-header {
    background: var(--surface-gray) !important;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 768px;
  }

  .am-modal-body .content-header {
    position: static;
    width: 100%;
  }
  body,
  html {
    display: block;
  }
  #root {
    margin-top: 80px;
    background-color: var(--surface-gray);
  }
  .primary-btn-fixed-wrap {
    padding: 20px 0;
  }
  .profiling-result .primary-btn-fixed-wrap {
    padding: 0 0 20px 0;
    background: #f9f9f9;
  }
  .bit-custom-discover-btn {
    margin: 20px 0 0 0;
  }
  .discover-starting-page .am-card {
    margin-right: 20px;
  }
  .discover-starting-page .am-card:nth-child(4),
  .discover-starting-page .am-card:nth-child(8) {
    margin-right: 0;
  }
  .content-wrapper.reksadana *,
  .content-wrapper.home.non-login * {
    z-index: 1;
  }

  .content-wrapper.home.non-login:before {
    content: '';
    background-image: linear-gradient(var(--surface), var(--surface-gray));
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
  .content-wrapper.mutual-fund-detail-modal:before {
    display: none;
  }
  .content-wrapper.reksadana .am-navbar-light,
  .content-wrapper.home.non-login .am-navbar-light {
    background: var(--surface);
  }
  .content-wrapper.home.non-login .bit-uikit-topbar {
    background: var(--surface);
  }
  .content-wrapper.home.non-login .content-header,
  .content-wrapper.reksadana .content-header {
    z-index: 2;
  }
  .content-wrapper.profiling {
    height: calc(100vh - 80px);
  }
  .index-quiz-wrapper .content-box {
    padding-bottom: 0;
  }
  .index-quiz-wrapper iframe {
    height: calc(100vh - 88px);
  }
  .boxloader + .bestofcontent {
    display: none;
  }
  .sell-order-detail-primary-btn-fixed-wrap {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0 20px;
    background: transparent;
    opacity: 1;
    /* To overlay .infoIcon (z-index: 99) */
    z-index: 100;
  }

  .order-detail-secondary-market-content-container {
    padding-bottom: 0;
  }

  .institution-registration-draft .am-navbar {
    background-color: var(--surface-gray);
  }
}

.bit-modal-pinform .am-modal-content {
  border-radius: 0;
}
.bit-modal-pinform .am-modal-body {
  height: 100vh;
}
@media (min-width: 1030px) {
  .bit-modal-pinform .am-modal-body {
    height: auto;
  }
  .pinform-switch-product .am-navbar-right {
    padding-right: 20px;
  }
}

.bit-small-circle-spinner .spinner {
  border: 2px solid #ccc;
  border-right-color: transparent;
  height: 20px;
  margin: 14px 5px;
  width: 20px;
}
.bit-porto-icon-circle-spinner .spinner {
  border: 2px solid #ccc;
  border-right-color: transparent;
  height: 20px;
  margin: 14px 5px;
  width: 20px;
}

.bit-small-circle-spinner.left .boxloader {
  width: unset !important;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.flex {
  display: flex;
}
.flex.vertical-center {
  align-items: center;
}
.flex.horizontal-center {
  justify-content: center;
}
.flex.space-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.flex.all-center {
  align-items: center;
  justify-content: center;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-right {
  justify-content: flex-end;
}
.flex-direction-column {
  flex-direction: column;
}
.block {
  display: block !important;
}

.autopay {
  border-radius: 4px;
  padding: 2px 4px;
}

.autopay.gopay {
  background: #00aed6;
}

.disable-action {
  pointer-events: none !important;
}
.reksadana-name-title {
  margin: 0px;
  text-align: left;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--text-primary);
}

.am-accordion .am-accordion-item .am-accordion-header {
  border-bottom: unset !important;
}

.am-accordion {
  border-top: unset !important;
}

.am-accordion
  .am-accordion-item
  .am-accordion-content
  .am-accordion-content-box {
  border-bottom: unset !important;
}

.bg-gradient-gray {
  background: linear-gradient(var(--surface), var(--surface-gray));
}

.bg-gray {
  background: #f9f9f9;
}

.border-radius-50 img {
  border-radius: 50%;
}

.border-radius-rounded {
  border-radius: 50%;
}

.modal-content .am-tabs-default-bar-top .am-tabs-default-bar-tab {
  border-bottom: 2px solid var(--modal-border) !important;
}

.am-tabs-default-bar-top .am-tabs-default-bar-tab {
  border-bottom: 2px solid var(--border) !important;
  z-index: 10;
}

.order-activity .am-tabs-default-bar {
  background-color: var(--surface) !important;
}

@media screen and (min-width: 1030px) {
  .order-activity .am-tabs-default-bar {
    background-color: var(--surface-gray) !important;
  }
}

.order-activity .am-tabs-default-bar-tab {
  color: var(--text-primary);
  border-bottom: 2px solid var(--border) !important;
}

html:not([data-scale]) .order-activity .am-tabs-default-bar-tab::after {
  background: none;
}

.order-activity .am-tabs-default-bar-tab.am-tabs-default-bar-tab-active {
  color: var(--green-default);
  font-weight: 700;
}

.am-tabs-default-bar-top .am-tabs-default-bar-tab:after {
  content: none;
}

.border-radius-4 {
  border-radius: 4px;
}

.bg-fade-blue-order-detail {
  background: var(--surface);
}

.bg-grey10 {
  background: var(--surface-gray);
}
.bg-white {
  background: white;
}
.bg-surface-gray {
  background: var(--surface-gray);
}
.mb-4 {
  margin-bottom: 4px;
}

/* Layouting Row Col */
.bit-row {
  display: flex;
  flex-flow: row wrap;
}

.bit-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}

.bit-col-50 {
  display: block;
  flex: 0 0 50%;
  max-width: 50% !important;
}

.bit-padding-top-10 {
  padding-top: 10px;
}

img.h80 {
  height: 80px;
}

/* Phone Input Style */
.PhoneInput {
  border-bottom: 1px solid var(--border);
}
.PhoneInputInput {
  border: none;
  height: 40px;
  background: transparent;
  color: var(--text-primary);
}
.PhoneInputCountry {
  margin-right: 0 !important;
}
.PhoneInputCountrySelectArrow {
  width: 8px !important;
  height: 8px !important;
  margin: 0 10px !important;
  color: var(--icon-default) !important;
  border-width: 6px 4px 0 !important;
  opacity: 1 !important;
  border-left: 0.3em solid transparent !important;
  border-right: 0.3em solid transparent !important;
  border-bottom-style: solid !important;
  border-top-style: solid !important;
  transform: none !important;
}
.PhoneInputCountryIcon {
  box-sizing: content-box;
  color: var(--icon-default);
}
.PhoneInputCountryIconImg {
  transform: scale(1.5);
}
.PhoneInputCountryIcon--border {
  border: 1px solid var(--border);
  box-shadow: none !important;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.height-100 {
  height: 100%;
}

.display-none {
  display: none;
}

.mr-0 {
  margin-right: 0 !important;
}

.RevisionForm .am-list-body {
  background-color: transparent;
}

.RevisionForm .am-list-item {
  min-height: 50px;
  background-color: unset;
}

.RevisionForm .am-list-line {
  border-bottom: none !important;
}

.RevisionForm .am-list-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RevisionForm .date-picker .am-list-item .am-list-line .am-list-arrow {
  background-image: none;
  background-image: url('assets/images/registration/calendar/light.svg');
  background-repeat: no-repeat;
  background-position: 96% 50%;
  transform: none;
  margin-right: 3px;
  width: 16px;
  height: 16px;
}

.RevisionForm.RevisionForm--dark
  .date-picker
  .am-list-item
  .am-list-line
  .am-list-arrow {
  background-image: url('assets/images/registration/calendar/dark.svg');
}

.login-register-heading {
  color: var(--text-primary);
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 0;
}
.change-phone-email {
  color: var(--text-primary);
}

/* Extra padding to give space for emojis on input form (iOS/safari) */
.am-list-item .am-input-control input {
  padding: 4px 0;
}

/* Line border color */
.lineborder.collection-1 {
  background: #ed7161 !important;
}
.lineborder.collection-2 {
  background: #3b5997 !important;
}
.lineborder.collection-3 {
  background: #88b337 !important;
}
.lineborder.collection-4 {
  background: #a97c50 !important;
}
.lineborder.collection-5 {
  background: #3b5997 !important;
}

.bit-product-list-modal-header {
  padding: 0 20px 0 20px;
}

@keyframes dash {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.isolate {
  isolation: isolate;
}

.product-category-card {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  align-items: center;
}

.card-display-image {
  width: 100%;
  height: auto;
  display: block;
}
.product-category-card .lineborder {
  position: absolute;
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 5px 0 0 5px;
}

.animated-red-dot {
  width: 12px !important;
  align-self: auto !important;
  margin-left: 4px;
}

.lineborder.pasar-uang {
  background: var(--green-default);
}
.lineborder.pasar-obligasi {
  background: var(--cobalt-default);
}
.lineborder.pasar-saham {
  background: var(--violet-default);
}
.lineborder.pasar-syariah {
  background: var(--olive-default);
}
.lineborder.pasar-lainnya {
  background: var(--blue-default);
}
.lineborder.pencairan-instan {
  background: #00ab6b !important;
}

.product-category-card > div {
  height: 100%;
}

.getstarted-title {
  font-size: 15px;
  margin-bottom: 3px;
  font-family: 'Proxima Nova Lt';
  font-weight: 600;
}
.product-category-card > div:nth-child(2) {
  margin-left: 4px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  align-self: flex-end;
}

.product-category-card > div:nth-child(3) {
  width: calc(100% - 85px);
  text-align: left;
  padding: 8px 15px;
  display: flex;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: var(--text-primary);
}

.product-category-content > div:nth-child(1) {
  font-size: 12px;
  color: #b5b5b5;
}
.product-category-content > div:nth-child(2) {
  margin: 2px 0 5px 0;
  font-weight: bold;
}
.product-category-content > div:nth-child(3) {
  font-size: 13px;
  color: var(--text-secondary);
}

.ptr--text,
.ptr--icon {
  color: var(--text-tertiary) !important;
}

.sbn-live-offering-icon {
  position: relative;
}
.sbn-live-offering-icon > img {
  z-index: 1;
}
.pulse {
  height: 44px !important;
  width: 44px;
  background: transparent;
  position: relative;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 50px;
  color: #ffffff;
}
.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #d96a65;
  border-radius: 50%;
  z-index: 0;
  opacity: 0.5;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.pulse:before {
  animation: pulse 2s ease-out infinite;
}
.pulse:after {
  animation: pulse 2s 1s ease-out infinite;
}
@keyframes pulse {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.profiling-card-fragment-inside-modal .profiling-card {
  background: var(--modal-surface);
  border-color: var(--modal-border) !important;
}

.insti-picker,
.am-picker-popup-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: var(--modal-surface);
}
.am-picker-col-item,
.am-picker-col-item-selected {
  font-size: 14px;
}
.am-picker {
  background: var(--modal-surface);
}
html[data-theme='dark'] .am-picker-col-item {
  color: var(--text-tertiary);
}
html[data-theme='dark'] .am-picker-col-item-selected {
  color: var(--text-primary);
}
html[data-theme='dark'] .am-picker-col-mask {
  background-image: var(--modal-surface);
}
html:not([data-scale]) .am-picker-col-indicator::before,
html:not([data-scale]) .am-picker-col-indicator::after,
html:not([data-scale]) .am-picker-popup-header::after {
  background-color: var(--modal-border);
}

@media screen and (min-width: 1030px) {
  .am-picker-col-indicator {
    border-top: 1px solid var(--modal-border);
    border-bottom: 1px solid var(--modal-border);
  }
  .am-picker-popup-header {
    border-bottom: 1px solid var(--modal-border);
  }
}

.crisp-client {
  position: absolute;
  z-index: 1000001;
}

.am-picker-popup-item-active {
  background-color: var(--modal-surface);
}

.has-tab .am-tabs-tab-bar-wrap:after {
  background-color: var(--surface-gray);
}

/* doesnt work in safari  */
.text-balance {
  text-wrap: balance;
}

.birthday-picker,
.education-picker,
.income-picker,
.income-source-picker,
.profile-risk-picker {
  background: transparent;
}

.education-picker .am-picker-popup-header,
.income-picker .am-picker-popup-header,
.income-source-picker .am-picker-popup-header {
  border-bottom: 1px solid var(--modal-border);
}

.education-picker .am-picker-col-indicator,
.income-picker .am-picker-col-indicator,
.income-source-picker .am-picker-col-indicator {
  border-top: 1px solid var(--modal-border);
  border-bottom: 1px solid var(--modal-border);
}

#blank-splash-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  background-color: var(--splash-screen-bg);
  display: none;
}
