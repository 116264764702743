/*  Styling For CreateSharedPortfolioModal.tsx */

@media screen and (min-width: 560px) {
  .shared-portfolio-confirm-modal .bit-modal-body {
    max-height: 600px;
  }
}
.edit-porto-icon {
  display: flex;
  margin-right: 12px;
}
.shared-porto-modal-icon {
  display: flex;
  margin-right: 12px;
}
.shared-portfolio-confirm-modal .modal-body {
  height: 100%;
  position: relative;
}
.contact-confirm-modal .modal-body {
  height: calc(100% - 45px);
  position: relative;
}

.shared-confirmation-body {
  padding: 24px 0px 0px;
}
.shared-confirmation-body > div:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.shared-confirmation-body-item {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}
.shared-confirmation-body-item .left-content {
  display: flex;
}
.shared-confirmation-body-item .right-content {
  margin-left: 12px;
}
.shared-confirmation-body-item .right-content > div:nth-child(2) {
  margin-top: 4px;
}

.shared-portfolio-confirm-modal .shared-confirmation-button {
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--modal-surface);
}
/* To hide loading spinning animation from antd */
.shared-portfolio-confirm-modal .bit-shared-button > img,
.shared-portfolio-confirm-modal .bit-shared-button > svg {
  display: none;
}

.shared-confirmation-button > button > div {
  color: var(--text-white);
}

/* Styling for ContactConfirmationModal.tsx */
.shared-porto-add-contact-confrimation {
  padding: 0 20px 60px;
}
.shared-porto-add-contact-confrimation.modal {
  padding: 20px 0px;
}
.shared-porto-add-contact-confrimation.modal.sell-all-modal {
  padding: 20px 0px 0px;
}
.shared-porto-add-contact-confrimation.switch-all-modal {
  padding: 20px 0px 0px;
}
.switch-all-success-modal {
  text-align: center;
}
.switch-all-success-modal > div:nth-child(2) {
  margin-top: 24px;
}

.shared-porto-add-contact-confrimation.modal .first-item {
  background-color: var(--modal-surface);
  margin: 0px;
  border-color: var(--modal-border);
  border-radius: 4px;
}
.shared-porto-add-contact-confrimation .first-item {
  background-color: var(--modal-surface);
  border-radius: 4px;
  margin: 4px 0 40px;
  border: 1px solid var(--modal-border);
}
.shared-porto-add-contact-confrimation.switch-all-modal .first-item {
  margin: 4px 0px 20px;
}
.shared-porto-add-contact-confrimation .first-item-title-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: var(--modal-green-low);
  border-radius: 4px 4px 0 0;
}

.shared-porto-add-contact-confrimation .first-item-title-wrapper .icon-wrapper {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: absolute;
}

.shared-porto-add-contact-confrimation .first-item-title-wrapper img {
  display: block;
  width: 100%;
}

.shared-porto-add-contact-confrimation .first-item-body-wrapper {
  padding: 12px;
  border-top: 1px solid var(--modal-border);
}
.shared-porto-add-contact-confrimation
  .first-item-body-wrapper
  > div:nth-child(1) {
  color: #949494 !important;
  padding-bottom: 2px;
}
.shared-porto-add-contact-confrimation > div:nth-child(2) {
  color: #949494 !important;
}
.shared-porto-add-contact-confrimation > div:nth-child(4) {
  color: #949494 !important;
}

.shared-porto-add-contact-confrimation .second-item {
  background-color: var(--modal-surface);
  margin: 12px 0 24px;
  border: 1px solid var(--modal-border);
  box-sizing: border-box;
  border-radius: 4px;
}
.shared-porto-add-contact-confrimation.switch-all-modal .second-item {
  margin: 12px 0px 0px;
}
.shared-porto-add-contact-confrimation.modal .second-item {
  background-color: var(--modal-surface);
  border-color: var(--modal-border);
  margin: 12px 0px 8px;
}
.shared-porto-add-contact-confrimation .selected-rec-portfolio,
.shared-porto-add-contact-confrimation .select-recurring-portfolio {
  margin: 12px 0 24px;
  border-color: var(--modal-border);
}
.shared-porto-add-contact-confrimation .selected-rec-portfolio {
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px var(--modal-border);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 48px;
  margin: 12px 0 24px;
  overflow: hidden;
}
.rec-move-unit {
  padding: 0px !important;
}
.rec-move-bar {
  background-color: var(--modal-border) !important;
}
.rec-move-unit div:nth-child(2) {
  word-break: break-all !important;
}
.shared-porto-add-contact-confrimation .empty-wrapper {
  margin: 4px 0 32px;
}
.shared-porto-add-contact-confrimation .second-item-row {
  padding: 0px 12px 0px 12px;
}
.second-item-row .bullet-phone {
  color: #949494 !important;
  font-weight: 400 !important;
}
.second-item-row > div:not(:last-child) {
  border-bottom: 1px solid var(--modal-border);
}
.second-item-row > div {
  padding: 12px 0px 12px 0px;
}
.shared-porto-add-contact-confirmation-button {
  bottom: 0px;
  width: 100%;
  padding: 20px;
  position: fixed;
  background-color: #ffffff;
}
.contact-confirm-modal
  .shared-porto-body-wrapper
  .add-contact-confirmation-first-item
  .first-item
  .first-item-title-wrapper
  > div:nth-child(2) {
  margin-left: 34px;
}
.add-contact-confirmation-first-item
  .first-item
  .first-item-title-wrapper
  > div:nth-child(2) {
  word-break: break-all;
  margin-left: 10px;
}
.shared-porto-add-contact-confirmation-button .am-button {
  cursor: pointer;
}
.shared-porto-add-contact-confirmation-button .am-button > div {
  font-size: 14px !important;
}
/* To hide loading spinning animation from antd */
.shared-porto-add-contact-confirmation-button .am-button > svg,
.shared-porto-add-contact-confirmation-button .am-button > img {
  display: none;
}

/* Styling for ContactSuccessModal.tsx */

.shared-porto-success-add-contact {
  text-align: center;
}
.shared-porto-success-add-contact > div:nth-child(2) {
  margin: 24px 0px 8px;
}

/* Styling for MemberDetailModal.tsx */

.shared-porto-member-detail {
  padding: 0px 20px 24px 20px;
}

/* Styling for MemberDetailModalConfirmation.tsc */

.shared-porto-delete-member {
  padding: 0px 20px 20px 20px;
}
.shared-porto-delete-member-button {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.shared-porto-delete-member-button .am-button-primary {
  display: flex;
  width: 50%;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.shared-porto-delete-member-button > button:nth-child(1) {
  margin-right: 10px;
}
.shared-porto-delete-member-button > a:nth-child(1) {
  background-color: #ffffff !important;
  color: #00ab6b !important;
  border: 1px solid #ededed;
}
/* To hide loading spinning animation from antd */
.shared-porto-delete-member-button .bit-confirm-btn > img,
.shared-porto-delete-member-button .bit-confirm-btn > svg {
  display: none;
}

/* Styling for ShareInvitationModal.tsx */
.shared-portfolio-invitation-modal .bit-modal-body {
  background-color: var(--modal-surface);
}
.shared-portofolio-invitation-modal-body .first-component {
  display: flex;
  border-bottom: 1px solid var(--modal-divider-thin);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.shared-portofolio-invitation-modal-body > div:nth-child(2) > b {
  word-break: break-all;
}
.shared-portofolio-invitation-modal-body .first-component .right-content {
  margin-left: 17px;
}
.shared-portofolio-invitation-modal-body .second-component {
  border: 1px solid var(--modal-border);
  border-radius: 4px;
  margin: 24px 0px 0px 0px;
  overflow: hidden;
}
.shared-portofolio-invitation-modal-body .second-component .top-component {
  padding: 12px 16px;
}
.thin-boundary {
  border-bottom: 1px solid var(--modal-border);
}
.shared-portofolio-invitation-modal-body .second-component .bottom-component {
  display: flex;
  padding: 11px 16px;
  align-items: center;
  background-color: var(--green-low);
}
.shared-portofolio-invitation-modal-body
  .second-component
  .bottom-component.dark {
  background-color: var(--green-high);
}
.shared-portofolio-invitation-modal-body
  .second-component
  .bottom-component
  > picture {
  display: flex;
}
.recurring-portfolio-opt {
  padding-bottom: 0px;
}
.recurring-portfolio-opt .bit-empty-state-switch {
  text-align: center;
}

.shared-portofolio-invitation-modal-body
  .second-component
  .bottom-component
  > div:nth-child(2) {
  margin-left: 9px;
}
.shared-portofolio-invite-member-button {
  display: flex;
}
.shared-portofolio-invite-member-button > a:nth-child(1),
.shared-portofolio-invite-member-button > button:nth-child(1) {
  width: 50%;
  margin-right: 8px;
  cursor: pointer;
  border-color: var(--modal-border);
}
.shared-portofolio-invite-member-button > a:nth-child(2),
.shared-portofolio-invite-member-button > button:nth-child(2) {
  width: 50%;
  cursor: pointer;
  color: var(--text-white);
}
.shared-portofolio-invite-member-button > button:nth-child(1) > svg,
.shared-portofolio-invite-member-button > button:nth-child(1) > img {
  display: none;
}
.shared-portofolio-invite-member-button > button:nth-child(2) > div {
  color: var(--text-white);
}

/* Styling for ShareInvitationConfirmationModal.tsx */

.modal-title-wrapper {
  display: flex;
}

.shared-portfolio-invitation-confirmation-modal .confirmRadio {
  padding-left: 0px;
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.shared-portfolio-invitation-confirmation-modal
  .confirmRadio.uncheck
  > div:nth-child(2)
  > img {
  display: none;
}
.shared-portfolio-invitation-confirmation-modal .confirmRadio > p {
  line-height: 1.5;
}
.shared-portfolio-invitation-confirmation-modal .modal-title {
  margin-left: 25px;
}
.shared-portofolio-invitation-confirmation-modal-body .modal-component-wrapper {
  margin: 24px 0px 0px;
}
.shared-portofolio-invitation-confirmation-modal-body .modal-component {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.shared-portofolio-invitation-confirmation-modal-body
  .modal-component
  > picture {
  display: flex;
  margin-right: 12px;
}
.shared-portofolio-invitation-confirmation-modal-body .modal-component > img {
  margin-right: 20px;
}
.shared-portofolio-invitation-confirmation-modal-body .term-condition > span {
  color: #00ab6b;
  text-decoration: underline;
}
.shared-portofolio-invitation-confirmation-modal-body .modal-footer {
  margin-top: 24px;
}
/* To hide loading spinning animation from antd */
.shared-portofolio-invitation-confirmation-modal-body
  .modal-footer
  .am-button-primary
  > svg,
.shared-portofolio-invitation-confirmation-modal-body
  .modal-footer
  .am-button-primary
  > img {
  display: none;
}

.shared-portfolio-invitation-confirmation-checkbox > input + div {
  margin-top: 0px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.shared-portfolio-invitation-confirmation-checkbox > input + div > svg > path {
  stroke: #fff;
}

.shared-portfolio-invitation-confirmation-checkbox > input + div + div {
  padding-left: 0px;
}

/* Styling for SharedPortofolioActionModal.tsx */

.shared-portfolio-more-modal .shared-portfolio-more-modal-body {
  padding: 30px 20px 38px 20px !important;
}

.shared-portfolio-more-modal .modal-component {
  display: flex;
  align-items: center;
}

.shared-portfolio-more-modal .modal-component:not(:last-child) {
  margin-bottom: 30px;
}
.shared-portfolio-more-modal .modal-component > img {
  margin-right: 12px;
}
.shared-portfolio-more-modal .bit-stay-header-modal {
  padding: 8px 20px;
}

.shared-portfolio-more-modal .modal-title {
  align-items: flex-start;
  justify-content: space-between !important;
}

.shared-portfolio-more-modal .cust-wrap-modal-title {
  margin-bottom: 0;
}

.shared-portfolio-more-modal .action-modal-title {
  word-break: break-all;
  line-height: 1;
  margin-bottom: 4px;
}

.shared-portfolio-more-modal .modal-title span {
  color: var(--text-primary);
}

/* Styling for LeavePortfolioReasonModal.tsx */

.shared-portofolio-leave-modal .modal-title {
  margin-left: 20px;
}

.shared-portfolio-leave-modal-body-reason {
  word-break: break-word;
}

.shared-portofolio-leave-modal-body .animated-background {
  height: 14px;
  width: 200px;
  margin: 10px 0px;
}

.delete-form-bottom {
  padding-bottom: 150px;
  transition: 0.2s ease-out;
}
.leave-form {
  transition: 0.2s ease-out;
}
.leave-form-bottom {
  padding-bottom: 96px;
}
.leave-form.confirmation-modal-content--dark
  .leave-form-confirmation-content-wrapper
  .top-content {
  background: var(--green-high);
}
.leave-form.confirmation-modal-content--dark .leave-form-confirmation-info {
  background: var(--modal-cobalt-low);
}
.leave-form .am-list-line {
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 0px 12px;
}
.leave-form .am-list-content {
  font-size: 14px !important;
}

.leave-form .am-list-item {
  min-height: 40px !important;
}
.leave-form .leave-form-header {
  font-size: 12px;
  font-weight: 700;
  color: var(--text-primary);
  font-size: normal;
  padding: 24px 0px 10px;
}

.leave-form .am-list-header {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #949494;
  padding: 20px 0px 12px;
}
.leave-form .bit-custom-dropdown-input {
  border-color: var(--modal-border);
}
.leave-form .bit-custom-dropdown-input + div {
  display: none;
}

.leave-form .bit-custom-dropdown-input.selected + div {
  display: block;
}

.leave-form-reason {
  margin: 16px 0px 8px;
}
.leave-form-reason > textarea {
  width: 100%;
  border: 1px solid var(--modal-border);
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 14px !important;
  font-weight: 400;
  font-style: normal;
  resize: none;
  min-height: 80px;
  background-color: var(--modal-surface);
  color: var(--text-primary);
}
.leave-form-reason > textarea.has-error {
  border: 1px solid var(--red-default);
}
.bit-recurring {
  padding-bottom: 0px !important;
}

.leave-form-reason .error-label {
  color: #ee4a49 !important;
}
.leave-form-reason .error-label-wrapper {
  display: flex;
  justify-content: space-between;
}

.shared-portofolio-leave-modal-button {
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
}

/* Styling for LeavePortfolioConfirmationModal.tsx */
.leave-form.confirmation-modal-content.dark
  .leave-form-confirmation-content-wrapper
  .top-content {
  background-color: var(--green-high);
}
.leave-form.confirmation-modal-content.dark .leave-form-confirmation-info {
  background-color: var(--modal-cobalt-low);
}

.shared-portofolio-leave-confirmation-modal .modal-title {
  margin-left: 25px;
}
.shared-portofolio-leave-confirmation-modal-button {
  display: flex;
  padding: 40px 0px 20px 0px;
}
.shared-portofolio-leave-confirmation-modal-button > a,
.shared-portofolio-leave-confirmation-modal-button > button {
  width: 50%;
}
.shared-portofolio-leave-confirmation-modal-button > a:nth-child(1),
.shared-portofolio-leave-confirmation-modal-button > button:nth-child(1) {
  margin-right: 8px;
}
.leave-form-confirmation-input {
  width: 100%;
  border: 1px solid var(--modal-border);
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 14px !important;
  font-weight: 400;
  font-style: normal;
  resize: none;
  word-wrap: break-word;
  color: var(--text-primary);
}
.leave-form-confirmation-content-wrapper {
  border: 1px solid var(--modal-border);
  border-radius: 4px;
}
.leave-form-confirmation-content-wrapper .top-content {
  padding: 12.8px;
  display: flex;
  align-items: center;
  background: var(--green-low);
}
.leave-form-confirmation-content-wrapper .top-content .icon-wrapper {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.leave-form-confirmation-content-wrapper .top-content img {
  width: 100%;
  display: block;
}
.leave-form-confirmation-content-wrapper .bottom-content {
  padding: 12px 16px 12px 12px;
}

.leave-form-confirmation-content-wrapper .animated-background {
  height: 12px;
  width: 120px;
  margin: 6px 0;
}
.leave-form-confirmation-info {
  margin-top: 20px;
  padding: 12px;
  border-radius: 4px;
  background: var(--modal-cobalt-low);
  position: relative;
  display: flex;
  align-items: center;
}
.leave-form-confirmation-info div {
  margin-left: 8px;
}
.leave-form-confirmation-info > img {
  position: absolute;
  top: 0px;
  right: 0px;
}

/* Styling for PortfolioAchieveModal.tsx */

.shared-portofolio-achieve-modal-body {
  background-image: url(../../../assets/images/shared-porto-modal/achieve-bg.svg);
  text-align: center;
}
.shared-portofolio-achieve-modal-body > div:nth-child(2) {
  margin: 24px 0px 8px;
}
.shared-portofolio-achieve-modal-footer {
  padding: 0 20px 20px 20px;
}
.shared-portofolio-achieve-modal-footer > a:nth-child(1),
.shared-portofolio-achieve-modal-footer > button:nth-child(1) {
  margin-bottom: 18px;
}
.shared-portofolio-achieve-modal-footer > button:nth-child(2)::before {
  border: 1px solid var(--modal-border) !important;
}
.switch-all-modal .bit-modal-body {
  height: 90% !important;
}

@media screen and (max-width: 360px) {
  .shared-porto-unallowed-button .am-button {
    padding: 0px 58px 0px 58px;
  }
  .shared-porto-delete-member {
    margin-top: 15px !important;
  }
}

/* Styling for SwitchAllConfirmationModal.tsx */
.switch-all-confirm-value {
  border: 1px solid var(--modal-border);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rec-move-unit .text-wrap-center {
  width: 100px;
}

/* Styling for app only modal */
/* need to be deleted after merged to branch develop */

@media only screen and (min-width: 1030px) {
  .app-only-modal-container .bit-modal-body {
    width: 360px;
  }

  .switch-all-modal .bit-modal-body {
    height: auto !important;
  }
}

.app-only-modal-container .bit-modal-body-close svg {
  stroke: #b5b5b5;
}

.shared-porto-body-wrapper {
  padding-top: 0px !important;
}

.achive-title {
  color: var(--text-primary);
}

.achive-desc {
  color: var(--text-primary);
}
