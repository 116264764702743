.bit-porto-card-top {
  position: relative;
  border-bottom: solid 1px var(--border);
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 9px 15px;
}
.choose-recurring-modal .get-started-page-reksadana .bit-porto-card-top {
  border-bottom: solid 1px var(--modal-border);
}
.get-started-page-reksadana .bit-porto-card-top {
  border-bottom: solid 1px var(--border);
}

.choose-product-modal-body .bit-porto-card-top {
  border-bottom: solid 1px var(--modal-border);
}

.bit-porto-card-top a {
  color: #00ab6b;
}
.porto-card-btm {
  padding: 10px 15px;
}
.bit-filter-result-row {
  overflow: hidden;
  position: relative;
}
.bit-filter-result-row .card-selected {
  border: 1px solid var(--green-default) !important;
}

.bit-filter-result-row.card-selected > div:nth-child(1) {
  border: 1px solid var(--green-default) !important;
}

.bit-filter-result-row a {
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  padding: 15px 20px;
  justify-content: space-between;
  width: 100%;
}
.bit-filter-result-row > div {
  align-items: center;
  background: var(--surface);
  border: 1px solid var(--border);
  border-radius: 5px;
  display: flex;
  margin-bottom: 10px;
  padding: 15px 20px;
  justify-content: space-between;
  width: 100%;
}
.content-box-inmodal .bit-filter-result-row > div,
.choose-product-modal-body .bit-filter-result-row > div {
  background: var(--modal-surface);
  border: 1px solid var(--modal-border);
}
.bit-filter-result-row.card-selected > div {
  border: solid 1px var(--green-default);
}
.bit-filter-result-row .roboSelected {
  background: #7fd5b5;
  position: absolute !important;
  display: block !important;
  width: 50px !important;
}
.bit-filter-result-row .roboSelected svg {
  top: 16px;
  right: 3px;
}
.bit-reksa-generic-card .porto-card-btm {
  padding: 10px 15px;
}
.bit-reksa-generic-card .porto-card-btm .greenval,
.bit-reksa-generic-card .porto-card-btm .redval {
  margin-left: -2.5px;
}
.bit-reksa-cards-wrap .am-card.with-footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (min-width: 1030px) {
  .bit-filter-result-row > div {
    cursor: pointer;
  }
  .bit-reksa-generic-card {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.bit-filter-reksa-pic {
  background: var(--border);
  border-radius: 50%;
  height: 34px;
  margin-right: 12px;
  overflow: hidden;
  position: relative;
  width: 34px;
}
.bit-filter-reksa-pic img {
  width: 100px;
}
.bit-filter-reksa {
  align-items: center;
  display: flex;
  flex: 1;
}

.bit-filter-reksa img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.bit-filter-reksa h3 {
  font-size: 14px;
  margin: 0 0 4px;
  padding-right: 10px;
  max-width: 80vw;
  word-break: break-word;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--text-primary);
}
.bit-filter-reksa p {
  color: var(--text-tertiary);
  font-size: 12px;
}
.bit-filter-reksa div:last-child {
  flex: 1;
}
/* To Fix android 6 issue */
.bit-filter-right-result {
  color: var(--text-primary);
  font-size: 14px;
}
.bit-right-result {
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bit-right-result div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.bit-filter-right-result.no-min-width {
  min-width: unset;
}
.bit-filter-return .bit-right-result,
.bit-filter-minimum .bit-right-result {
  color: #00ab6b;
  font-size: 14px;
  text-align: right;
  width: 100px;
}
.bit-font-12 {
  font-size: 12px;
}
.bit-filter-aum .bit-right-result,
.bit-filter-aum .bit-right-expanse,
.bit-filter-aum .bit-right-drawdown {
  color: #414141;
  font-size: 14px;
  text-align: right;
  width: 100px;
}
.bit-filter-drawdown .minus-drawdown {
  color: #ee6360;
  font-size: 14px;
  text-align: right;
  flex: 1;
  min-width: unset;
}
.plus-result,
.bit-right-result .plus {
  color: #00ab6b !important;
}
.minus-result,
.bit-right-result .minus {
  color: #ee6360 !important;
}
.null_result {
  color: #b5b5b5 !important;
}
.category-page-banner-wrapper.get-started-banner {
  border: 0px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
}

.wrapper-return-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrapper-images {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
}

.wrapper-images div {
  margin-left: 2px;
  margin-right: 2px;
  width: 18px;
  height: 18px;
}

@media screen and (max-width: 340px) {
  .bit-filter-reksa h3 {
    max-width: 70vw;
    font-size: 13px;
  }
  .bit-filter-right-result {
    font-size: 13px;
  }
  .bit-filter-result-row > div {
    padding: 14px;
  }
}

@media screen and (min-width: 341px) and (max-width: 375px) {
  .bit-filter-reksa h3 {
    max-width: 50vw;
  }
  .bit-filter-result-row > div {
    padding: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .porto-card-selected-right-content {
    display: none;
  }
}

.bit-filter-result-row .bit-filter-reksa h3.overflow-hidden {
  overflow: hidden !important;
}

@media screen and (min-width: 1030px) {
  .bit-porto-card-top .hide-in-1030 {
    display: none;
  }
  .bit-porto-card-top .show-in-1030 {
    display: flex;
    margin-right: 8px;
  }
}
@media screen and (max-width: 1029px) {
  .bit-porto-card-top .show-in-1030 {
    display: none;
  }
}

.porto-card-name .top-title {
  display: flex !important;
}

.porto-card-name .top-title > div:nth-child(1) {
  font-weight: bold;
  font-size: 15px;
  white-space: pre-line;
  margin-right: 6px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--text-primary);
}

.activeToggle {
  font-weight: normal;
}
