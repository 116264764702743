@keyframes show-error {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  15% {
    bottom: 100px;
    opacity: 1;
    visibility: visible;
  }
  85% {
    opacity: 1;
    bottom: 100px;
    visibility: visible;
  }
  100% {
    opacity: 0;
    bottom: 80px;
    visibility: hidden;
  }
}

.stock-order-warning {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 80px;
  left: 20px;
  right: 20px;
  background-color: #fef6f6;
  border-radius: 4px;
  padding: 12px 16px;
}

.order-warning-show {
  animation: show-error 3s ease;
}
