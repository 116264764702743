.not-found-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

@media screen and (min-width: 1030px) {
  .not-found-container {
    margin-top: -110px;
    padding-top: 110px;
  }
}

.button-redirect {
  padding: 0px 50px;
  width: 100%;
}

.button-redirect-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 20px;
}

.not-found {
  text-align: center;
  padding: 40px;
}

.not-found h3 {
  margin-top: 20px;
  margin-bottom: 16px;
}