.bit-pay-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;
}
.bit-pay-box {
  background: var(--modal-surface);
  border-radius: 4px;
  padding: 6px 14px 0;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 80%;
}
.bit-pay-box h3 {
  padding: 20px 0;
  color: var(--text-primary)
}
.bit-gopay-logo {
  margin-left: 4px;
  width: 68px;
}
.bit-bottom-box {
  background: var(--modal-surface-gray);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 18px 12px;
  margin-left: -14px;
  width: calc(100% + 28px);
}
.bit-btn-batal {
  color: var(--text-primary);
  font-weight: bold;
  padding: 0 10px;
  width: 40%;
}
.bit-btn-buka {
  background: #00ab6b;
  border-radius: 4px;
  color: #fff;
  height: 40px;
  margin-left: 6px;
  padding: 11px;
  width: 60%;
}

.bit-btn-buka.disable {
  opacity: 0.4;
}
.bit-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--text-tertiary)
}
@media (min-width: 1030px) {
  .bit-pay-box {
    width: 340px;
  }
  .bit-bottom-box div,
  .bit-close {
    cursor: pointer;
  }
}
