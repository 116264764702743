.bit-target-container {
  text-align: center;
  margin-bottom: -24px;
}
.bit-target-container >div >div{
  padding: 0px !important;
}
.bit-target-container >div >div >div{
  margin-bottom: 24px;
}
.bit-target-container >div >div >div:nth-child(10){
  margin-bottom: 0px;
}
.bit-target-container >div >div >div:nth-child(11){
  margin-bottom: 0px;
}
.bit-target-container >div >div >div:nth-child(11){
  margin-bottom: 0px;
}


