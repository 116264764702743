.bit-target-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 24px 0px 24px 0px;
  color: #000;
  font-size: 14px;
  position: relative;
  width: 100%;
}

.bit-target-item {
  margin-bottom: 14px;
  width: calc(100% / 3);
}

.bit-porto-goal-item-circle {
  background: #eee;
  border-radius: 50px;
  height: 50px;
  margin: 0 auto 10px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: contain;
}

.bit-porto-goal-item-circle-selected {
  background: #00ab6b;
  border: solid 2px #00ab6b;
}
