/* New CSS module for RecommendationCard.jsx */

.recommendation-card-header {
  flex: 1 1 45%;
}

@media only screen and (min-width: 1030px) {
  .recommendation-card {
    border-radius: 4px 4px 0 0 !important;
  }
  .recommendation-card > div:nth-child(2) {
    border-top: none;
  }
  .recommendation-card-header > div:last-child {
    display: none;
  }
}
