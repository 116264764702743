div {
  --amount-width: var(--amountWidth);
}
.container {
  height: 30px;
}
.container-blurred {
  width: 111px;
}
.container-unblurred {
  width: var(--amount-width);
}

.blurred-container {
  position: absolute;
  transform: translateY(8px);
}
.nominal-container {
  position: absolute;
}
.wrapper {
  display: flex;
  overflow: hidden;
  height: 34px;
}
.nominal-wrapper {
  margin-top: 2px;
}
.amount-wrapper {
  overflow: hidden;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}
.icon-margin {
  display: flex;
  align-items: flex-start;
  margin-left: 4px;
  padding-top: 6px;
}
.margin-right-4 {
  margin-right: 4px;
}

.animation-hide {
  opacity: 0;
  -webkit-animation: porto-hide 0.5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 0.1s;
  animation: porto-hide 0.5s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}
.animation-show {
  opacity: 1;
  -webkit-animation: porto-show 0.7s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 0.1s;
  animation: porto-show 0.7s;
  animation-fill-mode: both;
  animation-delay: 0.1s;
}

.container-show {
  width: var(--amount-width);
  -webkit-animation: container-show 0.5s;
  animation: container-show 0.5s;
}
.container-hide {
  width: 111px;
  -webkit-animation: container-hide 0.5s;
  animation: container-hide 0.5s;
}

.bouncy-left-to-right {
  width: var(--amount-width);
  -webkit-animation: bouncy-left-to-right 0.5s;
  animation: bouncy-left-to-right 0.5s;
}
.bouncy-right-to-left {
  width: 111px;
  -webkit-animation: bouncy-right-to-left 0.5s;
  animation: bouncy-right-to-left 0.5s;
}

@keyframes porto-show {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes porto-hide {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes container-show {
  0% {
    width: 111px;
  }
  30% {
    width: 101px;
  }
  50% {
    width: 101px;
  }
  100% {
    width: var(--amount-width);
  }
}

@keyframes container-hide {
  0% {
    width: var(--amount-width);
  }
  30% {
    width: calc(var(--amount-width) + 10px);
  }
  50% {
    width: calc(var(--amount-width) + 10px);
  }
  100% {
    width: 111px;
  }
}

@keyframes bouncy-left-to-right {
  0% {
    width: 111px;
  }
  30% {
    width: 121px;
  }
  50% {
    width: 121px;
  }
  100% {
    width: var(--amount-width);
  }
}

@keyframes bouncy-right-to-left {
  0% {
    width: var(--amount-width);
  }
  30% {
    width: calc(var(--amount-width) - 10px);
  }
  50% {
    width: calc(var(--amount-width) - 10px);
  }
  100% {
    width: 111px;
  }
}
