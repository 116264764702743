.iconSupportWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--border);
  border-radius: 30px;
  font-size: 12px;
  height: 24px;
  padding: 2px 8px;
  color: #00ab6b;
}

@media screen and (min-width: 1030px) {
  .iconSupportWrap {
    background: var(--border);
    padding: 0 12px;
    border: 0;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    cursor: pointer;
  }
}

@media screen and (max-width: 320px) {
  .iconSupportWrap {
    font-size: 12px;
    padding: 2px 5px;
  }
}

.iconSupportWrap img {
  margin-right: 4px;
}
