.bibit-main-app {
  height: 100%;
  overflow: hidden;
}

:global .profit-review-2022 {
  overflow: hidden;
}

@media only screen and (min-width: 1030px) {
  .bibit-main-app {
    height: 100%;
    overflow: hidden;
  }
  :global .has-tab {
    padding-bottom: 50px;
  }
  :global .premium.has-tab {
    margin-top: -80px;
  }
  :global .wealth-specialist {
    margin-top: -80px;
  }

  :global .profit-review-2022 {
    margin-top: -80px;
  }

  :global .remove-overflow :local .bibit-main-app {
    overflow: unset;
  }
}
