.asset-allocation {
  text-align: center;
  display: block;
}

.allocation-name {
  white-space: nowrap;
}

.circle {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 14px;
}

.circle > div {
  color: var(--surface);
}

/*=== Light Mode Color ===*/
.moneymarket {
  background-color: var(--green-high);
}
.moneymarket > div {
  color: var(--green-default);
}

.equity{
  background-color: var(--violet-high);
}
.equity > div {
  color: var(--violet-default);
}

.fixincome{
  background-color: var(--cobalt-high);
}
.fixincome > div {
  color: var(--cobalt-default);
}

.cash {
  background-color: var(--brown-high);
}
.cash > div {
  color: var(--brown-default);
}

.campuran{
  background-color: var(--blue-high);
}
.campuran > div {
  color: var(--blue-default);
}

.loader > div {
  color: transparent;
}

/* Styles for new portofolio top card new */

.asset-allocation .circle {
  width: 56px;
  height: 56px;
  margin-bottom: 14px;
}
.asset-allocation .circle.loader {
  background-color: var(--green-high);
}

/* End - Styles for new portofolio top card new */
  