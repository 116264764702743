.bit-modal-compare {
  min-height: 265px;
  top: unset;
}

.bit-modal-compare .bit-modal-body {
  border-top: 1px solid var(--modal-border);
  padding: 0;
}
.bit-modal-compare .bit-modal-body .bit-compare-holder {
  background: var(--modal-surface);
  border-radius: 10px 10px 0 0;
  padding: 20px;
  position: relative;
  z-index: 100;
}
.bit-modal-compare .bit-compare-attention {
  top: -40px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 13px 20px 13px;
  background-color: var(--modal-violet-low);
  border: none;
}
.bit-modal-compare .bit-modal-body {
  overflow: unset;
}
.bit-compare-menus {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.bit-compare-menus .bit-compare-menus-opt {
  align-items: center;
  color: #666;
  display: flex;
  justify-content: center;
  margin: 0 14px;
}
.bit-compare-menus-opt div {
  align-items: center;
  display: flex;
  justify-content: center;
  background: #CBCBCB;
  border-radius: 50%;
  height: 16px;
  margin-right: 8px;
  width: 16px;
}
.bit-compare-menus-opt.selected div {
  background: #00ab6b;
}
.bit-compare-menus-opt div img {
  width: 9px;
}
.bit-compare-menus-opt picture {
  display: flex;
}
.bit-compare-menus + div {
  background: #fff;
}
.bit-compare-modal {
  padding: 0 0 20px;
}
.bit-compare-modal b {
  font-size: 16px;
  text-align: left;
  padding: 0 20px 0 20px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content.category-compare-active {
  padding-bottom: 200px;
}
.bit-compare-modal.slide-up {
  background: #fff;
  border-top: 1px solid #ededed;
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  transform: translateY(205px);
  -webkit-transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content.category-compare-active .bit-compare-modal.slide-up {
  transform: translateY(0);
  -webkit-transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.bit-compare-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}
.bit-compare-wrapper > div {
  border-radius: 5px;
  margin: 10px 4px 0px;
  text-align: center;
  position: relative;
  width: 30%;
}
.bit-compare-wrapper > div > svg {
  display: none;
  position: absolute;
  right: 7px;
  top: 0;
  -webkit-animation: close-anim 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: close-anim 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.bit-compare-wrapper > div > svg.bit-compare-close-show {
  display: block;
}
@keyframes close-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bit-compare-wrapper > div h4 {
  margin: 5px 0 0;
  white-space: nowrap;
}
.bit-compare-wrapper > div p {
  font-size: 11px;
  color: var(--text-primary);
}
.bit-compare-logo {
  background: var(--modal-border);
  border-radius: 50%;
  height: 35px;
  margin: 8px auto 8px;
  overflow: hidden;
  width: 35px;
}
.bit-compare-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.24;
}
.bit-compare-logo img {
  width: 100%;
}
.bit-cancel-compare {
  color: #00ab6b;
  font-size: 12px;
  font-weight: normal;
}
.bit-compare-attention {
  align-items: center;
  background: #dbc0ed;
  border-radius: 12px 12px 0 0;
  color: var(--violet-default);
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 13px 20px 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
  border: 1px solid #9343c833;
}

.bit-compare-attention + .bit-compare-holder {
  box-shadow: none;
}
@media only screen and (min-width: 1030px) {
  .bit-compare-modal.slide-up {
    width: 360px;
  }
  .content.category-compare-active .bit-compare-modal.slide-up {
    bottom: 50%;
    -webkit-transform: translateY(324px);
    -ms-transform: translateY(324px);
    transform: translateY(324px);
  }
  .bit-compare-menus + div {
    background: none;
  }
  .bit-modal-compare {
    bottom: -40px;
    margin: 0 auto;
    min-height: 290px;
    width: 768px;
  }
  .bit-compare-wrapper > div > svg.bit-compare-close-show,
  .bit-compare-menus .bit-compare-menus-opt,
  .bit-cancel-compare {
    cursor: pointer;
  }
  .bit-compare-item-name {
    -webkit-line-clamp: 1;
  }
}
.bit-compare-modal-reksa b {
  font-size: 16px;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  color: var(--text-primary);
}
.bit-compare-modal-reksa .bit-compare-wrapper {
  margin-left: -20px;
  width: calc(100% + 40px);
}
.bit-compare-menus.visibility-hidden {
  height: 0px;
  margin: 0;
}
.bit-compare-holder .compare-product-button {
  margin-top: 15px;
  font-weight: 700;
  color: #ffffff;
}
.bit-compare-holder .compare-product-button:disabled {
  background-color: var(--inactive-button);
}
.bit-compare-holder .compare-product-button::before {
  border: none !important;
}
.compare-product-icon {
  margin-right: 8px;
  margin-bottom: 2px;
}
