.bit-jago-terms-conditions-modal-container .bit-modal-body {
  padding: 20px 0;
}

.bit-jago-terms-conditions-modal-container .bit-modal-header {
  padding: 0 20px 10px 20px;
}

.bit-jago-terms-conditions-list {
  padding-left: 20px;
}

.bit-jago-terms-conditions-list .item-wrapper {
  position: relative;
  margin-bottom: 16px;
}

.bit-jago-terms-conditions-list .item-wrapper .tnc-modal-description-text {
  margin-left: 8px;
  font-size: 13px;
  line-height: 17.55px;
}

.bit-jago-terms-conditions-list li::marker {
  color: var(--text-primary);
  font-size: 13px;
}

.bit-jago-terms-conditions-upper-side .tnc-modal-subtitle-wrapper {
  display: flex;
  margin-bottom: 12px;
}

.bit-jago-terms-conditions-upper-side .tnc-modal-subtitle-wrapper .app-logo {
  display: flex;
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.bit-jago-terms-conditions-upper-side
  .tnc-modal-subtitle-wrapper
  .app-logo
  img {
  height: 100%;
}

.bit-jago-terms-conditions-upper-side .tnc-modal-subtitle {
  font-size: 14px;
  line-height: 20px;
}

.unchecked > div:nth-child(2) {
  border-color: var(--icon-default);
}
.unchecked > div:nth-child(2) > img {
  display: none;
}

.bit-jago-terms-conditions-list li:not(:last-child) {
  margin-bottom: 16px;
}

.bit-jago-terms-conditions-bottom-side .am-button {
  margin-bottom: 10px;
}

.bit-jago-terms-conditions-bottom-side .am-checkbox-wrapper {
  display: flex;
}
.bit-jago-terms-conditions-bottom-side.orange-checked {
  position: relative;
  bottom: 0;
  z-index: 10;
  background: white;
}
.bit-jago-terms-conditions-bottom-side.orange-checked
  .am-checkbox-checked
  .am-checkbox-inner {
  background: #fdaf27 !important;
  border-color: #fdaf27 !important;
}

.bit-orange-button {
  background-color: #fdaf27 !important;
  color: #141414 !important;
  font-weight: 700 !important;
}

.bit-jago-terms-conditions-bottom-side .bit-orange-button {
  background-color: #fdaf27 !important;
  color: #141414 !important;
  font-weight: 700 !important;
}

.bit-jago-terms-conditions-bottom-side .am-button {
  font-weight: 700;
}

.bit-jago-terms-conditions-bottom-side .bit-orange-button.am-button-disabled {
  opacity: 0.6 !important;
}

.bit-jago-terms-conditions-text {
  color: var(--text-primary);
  line-height: 18px;
  font-size: 13px;
}

.bit-jago-advantages-accordion-body ol {
  padding: 10px 5px 0px 15px;
  margin: 0;
}

.bit-jago-advantages-accordion-body li {
  margin-bottom: 5px;
  color: var(--text-primary);
}

.bit-jago-advantages-accordion-body li a {
  color: #00ab6b;
}

.bit-jago-advantages-accordion-body .margin-left-10 {
  margin-left: 10px;
}

.bit-jago-tnc-cashback.bit-jago-tnc-box {
  padding: 10px 15px;
  margin-top: 4px;
  margin-bottom: 0;
  border: 1px solid var(--modal-border);
  color: var(--icon-default);
}

.bit-jago-tnc-form > label {
  align-items: start;
}

.bit-jago-tnc-form > label > input + div {
  width: 18px;
  height: 18px;
  margin-top: 2px;
}
