.stock-orderbook {
  border: 1px solid var(--border);
  border-radius: 8px;
  overflow: hidden;
}

.stock-orderbook :global(.am-accordion-item .am-accordion-header > i) {
  background-image: url('data:image/svg+xml,<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 11.5L6 6.5L1 1.5" stroke="%23A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.stock-orderbook--dark :global(.am-accordion-item .am-accordion-header > i) {
  background-image: url('data:image/svg+xml,<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 11.5L6 6.5L1 1.5" stroke="%237D7D7D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.orderbook-wrapper :global .am-accordion-item .am-accordion-header {
  display: flex;
  align-items: center;
  padding: 16px;
  height: auto;
}

.orderbook-panel >div:nth-child(1){
  padding: 0px 12px;
}

.orderbook-wrapper :global .am-accordion-item .am-accordion-header i {
  top: 20px;
  background-size: unset;
}

.orderbook-wrapper :global(.am-accordion-item .am-accordion-header),
.orderbook-wrapper :global(.am-accordion-item .am-accordion-content) {
  background-color: var(--surface);
}

html:not([data-scale]) .orderbook-wrapper::before {
  display: none;
}

html:not([data-scale])
  .orderbook-wrapper
  :global
  .am-accordion-item
  .am-accordion-content
  .am-accordion-content-box::after {
  display: none;
}

html:not([data-scale])
  .orderbook-wrapper
  :global
  .am-accordion-item
  .am-accordion-header::after {
  display: none;
}

.orderbook-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 16px 12px;
}

.orderbook-details > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  margin-top: 5px;
}

.orderbook-table {
  display: flex;
  min-height: 354px;
}

.orderbook-table > div {
  width: 50%;
  border-top: 1px solid var(--border);
  text-align: center;
}

.orderbook-table > div > div {
  display: flex;
}

.orderbook-bid > div > div {
  width: 55%;
  border-right: 1px solid var(--border);
  padding: 6px 0;
  height: 32px;
}

.orderbook-bid > div:first-child > div,
.orderbook-ask > div:first-child > div {
  border-bottom: 1px solid var(--border);
}

.orderbook-bid > div > div:first-child {
  width: 45%;
  text-align: center;
}

.orderbook-bid > div > div:last-child,
.orderbook-ask > div > div:first-child {
  cursor: pointer;
}

.orderbook-bid > div > div:last-child:hover,
.orderbook-ask > div > div:first-child:hover {
  background-color: var(--surface-gray);
}

.orderbook-ask > div > div {
  width: 55%;
  border-right: 1px solid var(--border);
  padding: 6px 0;
  height: 32px;
}

.orderbook-ask > div > div:last-child {
  width: 45%;
  border-right: none;
  text-align: center;
}
