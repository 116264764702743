.empty-state-portfolio {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 20px;
}

.empty-state-portfolio button {
  font-weight: 700;
}

.description {
  margin: 8px 0 16px;
}

.button{
  border-radius: 44px;
  height: 44px;
  font-size: 15px;
}

.image {
  margin-bottom: 16px;
}

.empty-state-portfolio > button {
  width: 200px;
  font-weight: 700;
}
