.bit-compare-menus {
  display: flex;
  justify-content: center;
  padding-top: 14px 0;
  background-color: var(--surface-gray) !important;
}

.bit-compare-menus-dark {
  display: flex;
  justify-content: center;
  padding: 14px 0;
  background-color: var(--modal-divider-thick) !important;
}
.bit-compare-menus-dark-inmodal {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  background-color: var(--modal-highlight) !important;
}
:global(.get-started-page-reksadana) .bit-compare-menus {
  background-color: var(--surface);
}
:global(.choose-product-modal-body) .bit-compare-menus,
:global(.modal-content) .bit-compare-menus {
  background-color: var(--modal-surface);
}

:global .modal-content :global .bit-porto-card-top {
  border-bottom: solid 1px var(--modal-border);
}

:global .modal-content :global .porto-card {
  background-color: var(--modal-surface);
}

:global(.bit-modal) .bit-compare-menus {
  background-color: var(--modal-surface);
}
.bit-compare-menus .bit-compare-menus-opt {
  align-items: center;
  color: #666;
  display: flex;
  justify-content: center;
  margin: 0 10px;
}
.bit-compare-menus-opt span {
  font-size: 13px;
  color: var(--text-secondary);
}
.bit-compare-menus-opt div {
  align-items: center;
  display: flex;
  justify-content: center;
  background: var(--inactive);
  border-radius: 50%;
  height: 20px;
  margin-right: 8px;
  width: 20px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 400;
}
.bit-compare-menus-opt picture {
  display: flex;
}

:global(.mutualfund-categories--dark) .bit-compare-menus-opt div,
:global(.bit-watchlist-wrap.--dark) .bit-compare-menus-opt > div {
  background-color: var(--inactive);
}
.bit-compare-menus-opt.selected div {
  background: var(--green-default) !important;
}
.bit-compare-menus-opt.selected span {
  color: #00ab6b !important;
}
.bit-compare-menus-opt div img {
  width: 12px;
}
.bit-compare-menus + div {
  background: var(--surface);
}

:global(.choose-product-modal-body) .bit-compare-menus + div,
:global(.choose-recurring-modal) .bit-compare-menus + div {
  background: var(--modal-surface) !important;
}

:global(.bit-modal) .bit-compare-menus + div {
  background: var(--modal-surface);
}
.visibility-hidden {
  height: 0px;
  margin: 0;
  display: none;
}

@media screen and (min-width: 1030px) {
  .bit-compare-menus {
    background: transparent;
  }
}
