.error-msg {
  color: var(--red-default);
  display: block;
  text-align: center;
  margin: 30px;
}

.btn-refetch {
  color: #00ab6b;
  display: block;
  width: 100px;
  padding: 10px;
  background: var(--surface);
  margin: auto;
  border: 1px solid #00ab6b;
  margin-top: 15px;
  border-radius: 5px;
}
