.continue-regis-modal {
  z-index: 9999999;
}
.continue-regis-modal-body .modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content-img {
  margin-bottom: 24px;
  width: 100%;
  text-align: center;
}

.modal-content-description {
  text-align: center;
  margin-bottom: 24px;
}
.modal-content-description .title {
  margin-bottom: 10px;
}

.continue-regis-modal .modal-content-description .title {
  margin-bottom: 12px;
}

.modal-content-btn {
  text-align: center;
}

.modal-content-btn .am-button-primary {
  padding: 0rem 1rem;
  display: inline-flex;
}

.continue-regis-modal .modal-content-btn .am-button-primary {
  padding: 0 36px;
}

.modal-content-btn .width-auto {
  width: auto;
}
