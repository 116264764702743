.porto-summary-card {
  display: flex;
  padding: 15px;
}
.porto-summary-card > div {
  width: calc(100% / 3);
  height: 100%;
}

.content-light-navbar {
  background-color: #fff !important;
}

.porto-summary-card > div:nth-child(1) {
  text-align: left;
}
.porto-summary-card > div:nth-child(2) {
  text-align: center;
}
.porto-summary-card > div:nth-child(3) {
  text-align: right;
}
.porto-summary-card > div > div:nth-child(1) {
  color: #b5b5b5;
  font-size: 13px;
  padding-bottom: 5px;
}
.porto-summary-card > div > div:nth-child(2) {
  font-weight: bold;
}

.porto-card-btm-child > div:nth-child(2) span {
  font-size: 13px;
}

.greenval {
  color: #00ab6b;
}

.get-started-page .am-tabs {
  overflow: visible !important;
}

.am-card:not(.am-card-full).no-border-left {
  border-left: 0 !important;
}

.bit-reksa-cards-wrap > div:nth-child(odd) .card-reksa-shake {
  -webkit-animation: jiggle-reksa-card 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: jiggle-reksa-card 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-delay: 0.6s;
}
.bit-reksa-cards-wrap > div:nth-child(even) .card-reksa-shake {
  -webkit-animation: jiggle-reksa-card 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: jiggle-reksa-card 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-delay: 0.6s;
}
.reksadana-category-padding {
  padding-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .reksadana-category-padding {
    padding-bottom: 20px;
  }
}
@keyframes jiggle-reksa-card {
  0% {
    transform: translateX(-5px);
  }
  12% {
    transform: translateX(5px);
  }
  25% {
    transform: translateX(-5px);
  }
  37% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  62% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  87% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* --- New Filter --- */
.bit-reksa-cards-wrap {
  background: var(--surface);
}
.bit-reksa-cards-wrap .am-card {
  background-color: var(--surface);
}
.bit-reksa-cards-wrap > div:nth-child(odd) .card-reksa-shake {
  -webkit-animation: jiggle-reksa-card 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: jiggle-reksa-card 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-delay: 0.6s;
}
.bit-reksa-cards-wrap > div:nth-child(even) .card-reksa-shake {
  -webkit-animation: jiggle-reksa-card2 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: jiggle-reksa-card2 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-delay: 0.6s;
}

/* --- Set Padding Bottom when compare modal shown */
.bit-reksa-compare-maximum-padding {
  padding-bottom: 70px !important;
}

.bit-reksa-cards-wrap.bit-reksa-compare-maximum-padding.compare-modal-padding-bottom {
  padding-bottom: 50px !important;
}

.content-box.content-box-inmodal,
.content-box.content-box-inmodal .puller-box {
  background: var(--modal-surface);
}

.content-box.content-box-inmodal .puller-box .bit-reksa-cards-wrap {
  background: var(--modal-surface) !important;
}

@keyframes jiggle-reksa-card {
  0% {
    transform: translateX(-2px);
  }
  30% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes jiggle-reksa-card2 {
  0% {
    transform: translateX(-2px);
  }
  30% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-7px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (min-width: 425px) {
  .bit-sorting-items.bit-sorting div {
    padding: 3px 22px;
  }
}
@media (max-width: 324px) {
  .bit-sorting-items.bit-sorting div {
    margin: 0 6px 14px;
  }
}

.wrapper-period {
  background: transparent !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
}

.wrapper-period span {
  color: var(--text-secondary);
}

.text-period {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-filter-period {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-filter-period img {
  margin-left: 6px;
  margin-bottom: -4px;
}

@media only screen and (min-width: 1030px) {
  .bit-reksa-cards-wrap {
    background: none !important;
  }
  .categories-page .am-navbar-right > svg {
    margin-right: 16px;
  }
  .bit-reksa-cards-wrap.bit-reksa-compare-maximum-padding.compare-modal-padding-bottom {
    padding-bottom: 80px !important;
  }
  .bit-reksa-fullheight {
    min-height: calc(100vh - 350px);
  }
}

.paddingBottom50 {
  padding-bottom: 50px;
}

.paddingTop20 {
  padding-top: 20px;
}

.reksadana-product-list-dark {
  background: var(--modal-surface);
}
