.wrapper {
  min-width: 65px;
}
.blurred {
  position: relative;
}
.dot-wrapper {
  position: absolute;
}
.right {
  right: 0;
}
.align-right {
  text-align: right;
}
.hide {
  opacity: 0;
}
.show {
  opacity: 1;
}
