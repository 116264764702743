/* .bit-loading-skeleton {
  border-radius: 4px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, var(--modal-surface-gray) 8%, var(--modal-surface) 18%, var(--modal-surface-gray) 33%);
  background-size: 800px 104px;
} */

.bit-loading-skeleton {
  border-radius: 4px;
  animation: waveKeyFrame 1s linear 0s normal forwards infinite;
  background: linear-gradient(
    to right,
    var(--primary-skeleton) 0%,
    var(--gradient-skeleton) 50%,
    var(--primary-skeleton) 0
  );
  background-size: 200% 100%;
  background-position: right;
}


