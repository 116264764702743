.toastSlideUp {
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px 0 #e2eaf1;
  background-color: #0085e4;
  color: #fff;
  width: calc(100% - 40px);
  margin: 0 20px;
  position: fixed;
  z-index: 1001;
  transform: translateY(120px);
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  padding: 8px 12px;
  bottom: 60px;
  left: 0;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  justify-content: center;
  line-height: 16px;
  opacity: 0;
}
@media (min-width: 1030px) {
  .toastSlideUp {
    width: 380px !important;
    left: 50%;
    margin-left: -190px;
  }
}
.toastSlideUp.red {
  background-color: #3b3b3b;
  color: #ffffff;
  padding: 16px 21px;
  box-shadow: none;
  justify-content: space-between;
}

.toastSlideUp.red > div {
  align-items: center;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.toastSlideUp.red span {
  background: #ee4a49;
  border: solid 4px #ee4a49;
  margin-right: 13px;
}

.toastSlideUp.red span svg {
  stroke-width: 3.6px;
}

.toastSlideUp.lightgreen {
  background-color: var(--green-low);
  border: 1px solid var(--green-high);
  color: var(--green-default);
  padding: 16px;
  box-shadow: none;
  justify-content: space-between;
}

.toastSlideUp > div {
  display: flex;
}

.toastSlideUp.black {
  background-color: #3b3b3b;
  color: #ffffff;
  padding: 16px 21px;
  box-shadow: none;
  justify-content: space-between;
}

.toastSlideUp.black > div {
  align-items: center;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.toastSlideUp.black span {
  background: var(--green-default);
  border: solid 4px var(--green-default);
  margin-right: 13px;
}

.toastSlideUp.black span svg {
  stroke-width: 3.6px;
}

.toastSlideUp span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #fff;
  border-radius: 20px;
  margin-right: 8px;
}

.toastSlideUp.lightgreen span {
  border: none;
}

.toast-redirect-link {
  cursor: pointer;
  font-weight: bold;
  padding-left: 8px;
}

.toast-close-button {
  cursor: pointer;
}

.toast-close-button svg {
  stroke-width: 3.4px;
}

.move-up-toast {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}
.move-up-toast.lightgreen {
  transform: translateY(-32px);
}
@keyframes toast-move {
  0% {
    transform: translatey(120px);
  }
  100% {
    transform: translatey(0px);
  }
}
.move-down-toast {
  transform: translatey(120px);
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}
@keyframes toast-down {
  0% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(120px);
  }
}
