.bit-product-modal-header {
  padding: 20px 20px 14px 20px;
}

.bit-product-modal-body {
  min-height: 100%;
}

.bit-modal-content {
  height: 100%;
  overflow-y: scroll;
}


@media (min-width: 1030px) {
  .bit-modal-content {
    padding: 20px 20px 100px 20px;
  }
}
