.bit-modal {
  bottom: 0;
  left: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
}
.bit-modal.modal--visible {
  pointer-events: visible;
}
.bit-modal .bit-modal-body {
  transform: translate3d(0, 100%, 0);
}
.bit-modal.modal--visible .bit-modal-body {
  transform: translate3d(0, 0, 0);
}
.bit-modal .bit-modal-mask {
  display: none;
}
.bit-modal.modal--visible .bit-modal-mask {
  display: block;
}
.bit-modal.is-animating .bit-modal-body {
  position: fixed;
}
.bit-modal-body {
  background: var(--modal-surface);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  max-height: 100vh;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
}
.bit-modal-body h3 {
  margin: 0;
  color: var(--text-primary);
}
.bit-modal-mask {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  transform: translate3d(0, 0, 0);
  width: 100%;
}
.bit-modal-body .bit-title-text h3 {
  font-size: 16px;
  margin: 0;
  color: var(--text-primary);
}

.bit-porto-selection-modal .bit-title-text h3 {
  margin-left: 20px;
}

.bit-porto-selection-modal .bit-modal-body {
  padding-bottom: 0;
}
.bit-porto-selection-modal .bit-modal-header {
  margin-bottom: 20px;
}

.tnc-modal .bit-modal-body {
  padding-bottom: 0;
}

.bit-modal-compare {
  top: initial !important;
}

.bit-modal-compare,
.bit-modal-compare > div {
  max-height: 100%;
}

/* Target iphone 7 portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .tnc-modal .bit-modal-header {
    flex-shrink: 0;
    align-items: center;
    min-height: 20px;
  }
}

@media only screen and (min-width: 1030px) {
  .bit-modal {
    align-items: center;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1000;
  }
  .bit-modal-body {
    border-radius: 5px;
    min-width: 400px;
    max-width: 768px;
    position: relative;
  }
  .bit-modal .bit-modal-body {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 100%, 0);
  }
  .bit-modal.is-animating .bit-modal-body {
    position: relative;
  }
  .bit-modal.modal--visible .bit-modal-body {
    opacity: 1;
    pointer-events: visible;
    transform: translate3d(0, 0, 0);
    border-radius: 12px;
  }
  .bit-modal.bit-modal-compare .bit-modal-body {
    transform: translate3d(0, 100%, 0);
    max-width: unset;
  }
  .bit-modal.modal--visible.bit-modal-compare .bit-modal-body {
    transform: translate3d(0, 0, 0);
  }
  .bit-modal-mask {
    position: fixed;
  }
  .tnc-modal .bit-modal-body {
    padding-bottom: 20px;
  }

  .modal-compare-radius .modal-content {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
  }
}
